import React from 'react';
import { AOO_API_HOST } from "../../../env";
import { postAction, postQueryAction } from "../WidgetActions";
import DisplayIcon from "../FailureIcon";
import { SubMenuItem } from './SubMenu';

// Worker menu items generator
export const useWorkerItems = (
  workers: any[], 
  machineId: string, 
  onClose: () => void
): SubMenuItem[] => {
  if (!workers?.length) return [];
  
  return workers.map(worker => ({
    id: worker.WorkerId,
    text: worker.FullName,
    onClick: (e) => {
      e.stopPropagation();
      postAction(
        AOO_API_HOST + `/aoo-api/Productions/post-new-flag`,
        {
          MachinesId: [machineId],
          WorkerId: worker.WorkerId,
          WorkerType: "W",
        }
      );
      onClose();
    }
  }));
};

// Inspector menu items generator
export const useInspectorItems = (
  workers: any[], 
  machineId: string, 
  onClose: () => void
): SubMenuItem[] => {
  if (!workers?.length) return [];
  
  return workers.map(worker => ({
    id: worker.WorkerId,
    text: worker.FullName,
    onClick: (e) => {
      e.stopPropagation();
      postAction(
        AOO_API_HOST + `/aoo-api/Productions/post-machine-inspector`,
        {
          MachinesId: [machineId],
          WorkerId: worker.WorkerId,
        }
      );
      onClose();
    }
  }));
};

// Reason menu items generator
export const useReasonItems = (
  reasons: any[], 
  eventId: string, 
  eventState: string,
  onClose: () => void
): SubMenuItem[] => {
  if (!reasons?.length) return [];
  
  return reasons.map(reason => ({
    id: reason.Id,
    text: reason.Name,
    icon: <DisplayIcon value={reason.Name} height={20} width={25} color={"#363636"} />,
    onClick: (e) => {
      e.stopPropagation();
      postAction(
        AOO_API_HOST + `/aoo-api/Productions/post-failure-reason`,
        {
          EventId: eventId,
          ReasonId: reason.Id,
          Status: eventState,
        }
      );
      onClose();
    }
  }));
};

// Fix days menu items generator
export const useFixItems = (
  hasDaysToFix: boolean,
  onOpenFixModal: () => void,
  onOpenRemoveModal: () => void,
  onClose: () => void
): SubMenuItem[] => {
  const items: SubMenuItem[] = [];
  
  items.push({
    id: 'set-fix',
    text: hasDaysToFix ? "Edit days to fix" : "Set days to fix",
    onClick: (e) => {
      e.stopPropagation();
      onOpenFixModal();
      onClose();
    }
  });
  
  if (hasDaysToFix) {
    items.push({
      id: 'remove-fix',
      text: "Remove days",
      onClick: (e) => {
        e.stopPropagation();
        onOpenRemoveModal();
        onClose();
      }
    });
  }

  return items;
};

// Cover picture menu items generator
export const useCoverItems = (
  hasCover: boolean,
  machineId: string,
  onOpenCoverModal: () => void,
  onClose: () => void
): SubMenuItem[] => {
  const items: SubMenuItem[] = [];
  
  items.push({
    id: 'edit-cover',
    text: hasCover ? "Edit cover picture" : "Add cover picture",
    onClick: (e) => {
      e.stopPropagation();
      onOpenCoverModal();
      onClose();
    }
  });
  
  if (hasCover) {
    items.push({
      id: 'remove-cover',
      text: "Remove cover picture",
      onClick: (e) => {
        e.stopPropagation();
        postQueryAction("RemoveCoverPicture?MachineId", machineId);
        onClose();
      }
    });
  }

  return items;
}; 