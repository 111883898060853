import React, { useEffect, useState } from "react";
import { ResolvedContent } from "./InspectionFlags_styles";
import axios from "axios";
import useSWR from "swr";

import SendCancelled from "../../../assets/icons/send-cancelled.svg";
import { AOO_API_HOST } from "../../../env";
import { useNavigate } from "react-router-dom";
import { NavLink } from "../NavbarStyles";
import Routes from "../../Data/Routes";

function getDateString(targetDate) {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let currentDate = new Date(targetDate);
  let formatted_date =
    currentDate.getUTCFullYear() +
    " " +
    (currentDate.getMonth() > 8 ? "" : "0") +
    (parseInt(currentDate.getMonth()) + 1).toString() +
    " (" +
    month[currentDate.getUTCMonth()] +
    ") " +
    (currentDate.getUTCDate() > 9 ? "" : "0") +
    currentDate.getUTCDate();
  return formatted_date;
}

function Widget(props) {
  const { data } = props;

  return (
    <div className="resItem-border">
      <div
        className={
          data.IsCompleted ? "resItem resItem-blue" : "resItem resItem-gray"
        }
      >
        <div className="resItem-top">
          <div className="resItem-machineCode">{data.MachineCode}</div>
          <p
            className={
              data.Name.length < 40
                ? "resItem-machineName"
                : "resItem-machineName resItem-machineName-small"
            }
          >
            {data.Name}
          </p>
        </div>
        <div className="resItem-bottom">
          <p className="resItem-workerName">{data.Worker}</p>
          <div className="resItem-icon">
            {data.IsCompleted ? "" : <img src={SendCancelled} alt=""/>}
          </div>
          <p className="resItem-date">{getDateString(data.EntryDate)}</p>
        </div>
      </div>
    </div>
  );
}

function Resolved() {
  let navigate = useNavigate();

  const [data, setData] = useState([]);

  const fetcher = (url) =>
    axios
      .request({
        url: url,
        method: "get",
        baseURL: AOO_API_HOST,
        params: {},
        responseType: "json",
      })
      .then((res) => setData(res.data));
  const { result } = useSWR("/aoo-api/Productions/review-completed-flags", fetcher, {
    refreshInterval: 10000,
  });

  useEffect(() => {
    if (result !== undefined) {
      setData(data);
    }
  }, [result,data]);

  return (
    <div className="res">
      <NavLink
        onClick={() => {
          navigate(Routes.Main);
        }}
        style={{ marginRight: 30 }}
      >
        Back to menu
      </NavLink>
      <p className="resTitle">Review resolved flags</p>
      <ResolvedContent>
        {data.map((e, i) => {
          return <Widget key={e.MachineId + i} data={e} />;
        })}
      </ResolvedContent>
    </div>
  );
}

export default Resolved;
