import { MachinesData } from "../../context/MachinesContext";

interface WorkerDataItem {
  FullName: string;
  Initials: string;
}
interface CoverImage {
  ImageData: Uint8Array;
  Content: string;
}
export interface MachineDataItem {
  Id: string;
  EventId: string;
  Name: string;
  EventDuration: number;
  EventsCount: number;
  MachineCode: string;
  Workers: WorkerDataItem[];
  MachineReason: string;
  IsUrgent: boolean | null;
  EventState: string;
  DaysToFix: number | null;
  Cover: CoverImage | null;
  IsAvailable: boolean;
}

interface WidgetData {
  redMachines: Array<MachineDataItem>;
  yellowMachines: Array<MachineDataItem>;
  blueMachines: Array<MachineDataItem>;
}

const defWidgetData: WidgetData = {
  redMachines: [],
  yellowMachines: [],
  blueMachines: [],
};

const prepWidgetData = (data: MachinesData | null) => {
  let result: WidgetData = { ...defWidgetData };
  result.redMachines = [];
  result.yellowMachines = [];
  result.blueMachines = [];
  if (data === null || data === undefined) {
    return result;
  }
  data.RedMachines.map((machines) =>
    result.redMachines.push({
      Id: machines.Id,
      EventId: machines.EventId,
      Name: machines.Name,
      EventDuration: machines.EventDuration,
      EventsCount: machines.EventsCount,
      MachineCode: machines.MachineCode,
      Workers: machines.Workers,
      MachineReason: machines.MachineReason,
      IsUrgent: machines.IsUrgent,
      EventState: machines.EventState,
      DaysToFix: machines.DaysToFix,
      Cover: machines.Cover,
      IsAvailable: machines.IsAvailable,
    })
  );
  data.YellowMachines.map((machines) =>
    result.yellowMachines.push({
      Id: machines.Id,
      EventId: machines.EventId,
      Name: machines.Name,
      EventDuration: machines.EventDuration,
      EventsCount: machines.EventsCount,
      MachineCode: machines.MachineCode,
      Workers: machines.Workers,
      MachineReason: machines.MachineReason,
      IsUrgent: machines.IsUrgent,
      DaysToFix: machines.DaysToFix,
      Cover: machines.Cover,
      EventState: machines.EventState,
      IsAvailable: machines.IsAvailable,
    })
  );
  data.BlueMachines.map((machines) =>
    result.blueMachines.push({
      Id: machines.Id,
      EventId: machines.EventId,
      Name: machines.Name,
      EventDuration: machines.EventDuration,
      EventsCount: machines.EventsCount,
      MachineCode: machines.MachineCode,
      Workers: machines.Workers,
      MachineReason: machines.MachineReason,
      IsUrgent: machines.IsUrgent,
      DaysToFix: machines.DaysToFix,
      EventState: machines.EventState,
      Cover: machines.Cover,
      IsAvailable: machines.IsAvailable,

    })
  );
  return result;
};

export { prepWidgetData };
