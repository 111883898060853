import React, { useContext } from "react";

import { UrgencyOption } from "../WidgetActions";
import { GlobalContext } from "../../../context/GlobalContext";

const EventMenuItem = ({ 
  setsubStatusRoot, 
  onOptionClick,
  activeSubmenu,
  onToggle,
  onTouchStart,
  onTouchEnd,
  onTouchCancel,
  submenuStyle,
  onSubmenuTouch,
  onSubmenuScroll,
  onMouseEnter,
  onMouseLeave
}) => {
  const { globalStatus } = useContext(GlobalContext);
  const options = UrgencyOption(globalStatus);
  const isActive = activeSubmenu === 'status';
  
  return (
    <li 
      className={`menu-item status ${isActive ? 'active' : ''}`}
      onClick={onToggle('status')}
      onTouchStart={onTouchStart('status')}
      onTouchEnd={onTouchEnd('status')}
      onTouchCancel={onTouchCancel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      Change event status
      <ul
        className={`menu-sub-list status ${isActive ? 'open-sub-menu' : ''}`}
        ref={(ref) => setsubStatusRoot(ref)}
        style={isActive ? submenuStyle('status') : {}}
        onTouchStart={onSubmenuTouch}
        onTouchMove={onSubmenuTouch}
        onTouchEnd={onSubmenuTouch}
        onWheel={onSubmenuScroll}
        onMouseLeave={(e) => {
          if (onMouseLeave && !(e.relatedTarget === e.currentTarget.parentElement)) {
            onMouseLeave(e);
          }
        }}
        onMouseEnter={(e) => {
          e.stopPropagation();
        }}
      >
        {options.map((option, key) => (
          <li
            key={key}
            className={"menu-button"}
            onClick={(e) => {
              e.stopPropagation();
              onOptionClick(option);
            }}
          >
            {option.Title}
          </li>
        ))}
      </ul>
    </li>
  );
};

export default EventMenuItem;
