import React, { useCallback, useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { SlackViewScanQrCode } from './SlackViewScanQrCode';
import { SlackViewSearchInput } from './SlackViewSearchInput';
import { SlackViewViewSwitch } from './SlackViewViewSwitch';
import { SlackViewSortBy } from './SlackViewSortBy';
import { SlackViewDateSelector } from './SlackViewDateSelector';
import { SlackViewDepartmentSelector } from './SlackViewDepartmentSelector';
import { SlackViewColorSelector } from './SlackViewColorSelector';
import { SlackViewUIContext } from '../Context/SlackViewUIContext';
import {SlackSearchFiltersContext} from "../Context/SlackSearchFiltersContext";
import BackgroundLetterAvatars from '../../Widgets/Log/components/Avatar';
import SimpleDialog, {UserDialogMode} from '../../Widgets/Log/components/userSelect';
import { SlackSearchResultsContext } from '../Context/SlackSearchResultsContext';

interface SlackViewMobileSearchBarProps {}

const SearchBarContainer = styled.div`
    background-color: #5e365f;
    overflow: hidden;
    transform-origin: top;

    &.searchbar-enter {
        opacity: 0;
        transform: translateY(-100%);
    }
    &.searchbar-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
        opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    &.searchbar-exit {
        opacity: 1;
        transform: translateY(0);
    }
    &.searchbar-exit-active {
        opacity: 0;
        transform: translateY(-100%);
        transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
        opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }
`;

const InnerContainer = styled.div`
    padding: 10px;
`;

const TopRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const SlackViewMobileSearchBar: React.FC<SlackViewMobileSearchBarProps> = () => {
    const [isViewSwitched, setIsViewSwitched] = useState(false);

    const { isSearchOpen } = useContext(SlackViewUIContext);
    const filtersContext = useContext(SlackSearchFiltersContext);
    const {selectedInspector, setSelectedInspector} = useContext(SlackSearchResultsContext);
    const nodeRef = useRef(null);

    const toggleView = useCallback(() => setIsViewSwitched((prev) => !prev), []);

    const onMachineIdCaptured = useCallback((machineId: string) => {
        filtersContext.setSearchInput(machineId);
    }, [filtersContext]);
    
    return (
        <CSSTransition
            in={isSearchOpen}
            timeout={250}
            classNames="searchbar"
            unmountOnExit
            nodeRef={nodeRef}
        >
            <SearchBarContainer ref={nodeRef}>
                <InnerContainer>
                    {isViewSwitched ? (
                        <SlackViewSortBy />
                    ) : (
                        <>
                            <TopRowContainer>
                                <SlackViewSearchInput />
                                <SlackViewScanQrCode onMachineIdCaptured={onMachineIdCaptured} />
                                <SimpleDialog
                                    key={`simple-dialog-${UserDialogMode.machineSearchFilter}`}
                                isReadOnly={false}
                                mode={UserDialogMode.machineSearchFilter}
                                setPoster={setSelectedInspector}
                                poster={selectedInspector}
                                />
                            </TopRowContainer>
                        </>
                    )}
                    <SlackViewViewSwitch onClick={toggleView} />
                    <SlackViewDateSelector />
                    <SlackViewDepartmentSelector />
                    <SlackViewColorSelector />
                </InnerContainer>
            </SearchBarContainer>
        </CSSTransition>
    );
};

export default SlackViewMobileSearchBar;
