import React, { StrictMode } from "react";
import { isMobile } from "react-device-detect";
import { SlackViewDesktop } from "./SlackViewDesktop/SlackViewDesktop";
import { SlackViewMobile } from "./SlackViewMobile/SlackViewMobile";
import { LogProvider } from "../../context/LogContext";
import { SearchFiltersProvider } from "./Context/SlackSearchFiltersContext";
import { SearchResultsProvider } from "./Context/SlackSearchResultsContext";
import { SlackViewUIContextProvider } from "./Context/SlackViewUIContext";

export const SlackView = () => {
  return (
    <StrictMode>
      <SearchFiltersProvider>
        <SearchResultsProvider>
          <SlackViewUIContextProvider>
            <LogProvider>
              {isMobile ? <SlackViewMobile /> : <SlackViewDesktop />}
            </LogProvider>
          </SlackViewUIContextProvider>
        </SearchResultsProvider>
      </SearchFiltersProvider>
    </StrictMode>
  );
};
