import React, {useState, useContext, useEffect, useCallback, useMemo} from "react";
import axios from "axios";
import { postWaitAction } from "../../WidgetActions";
import { isBrowser } from "react-device-detect";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { GlobalContext } from "../../../../context/GlobalContext";
import { LogContext, initialPostData } from "../../../../context/LogContext";
import Uploader from "./Uploader";
import SimpleDialog, { UserDialogMode } from "./userSelect";
import { Icons } from "../icons/Icons";
import Clip from "../icons/Clip";
import { AOO_API_HOST } from "../../../../env";
import { getDataById } from "../api/actions";
import DraggableInput from "./DraggableInput";
import Checkbox from "@mui/material/Checkbox";
import MultilineInput from "./Input"
import ColorChange from "./ColorChange"
import {
  FormContainer,
  Input,
  Form,
  InputContainer,
  ActionContainerRight,
  ActionContainerLeft,
  RightContainer,
  LeftContainer,
  Label,
  ActionButton,
  ExitEditMode,
} from "../style/LogStyles";
import {SlackSearchResultsContext} from "../../../Slack/Context/SlackSearchResultsContext";
import { SlackLocalStoreManager } from "../../../Slack/Utils/SlackLocalStoreManager";

const FormComponent: React.FC<{
  id: string;
  event: boolean;
  setEvent: React.Dispatch<React.SetStateAction<boolean>>;
  isReadOnly: boolean;
}> = ({ id, event, setEvent, isReadOnly = false }) => {
  const [impr, setImpr] = useState<boolean>(false);
  const [question, setQuestion] = useState<boolean>(false);
  const [comment, setComment] = useState<boolean>(false);
  const [fetched, setFecthed] = useState<string>("");
  const {
    setMachineId,
    setLog,
    menuClicked,
    setMenuClicked,
    setisNavActive,
    backtoMenu,
    setBacktoMenu,
  } = useContext(GlobalContext);
  const slackSearchResultsContext = useContext(SlackSearchResultsContext)
  // const [cookies, setCookie] = useCookies();
  const { machine, eventOption, setEventOption, machineId } = useContext(GlobalContext);
  const {
    editMode,
    setEditMode,
    setFiles,
    files,
    deleted,
    setReset,
    postData,
    setPostData,
    poster,
    setPoster,
    content,
    noUserSelect,
    setNoUserSelect,
  } = useContext(LogContext);
  const [checked, setChecked] = useState(false);
  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const MachineColorChange = useMemo(() => {
    const c3 = machine?.IsUrgent ? machine?.EventState + "-U" : machine?.EventState;
    const c2 = eventOption.isUrgent ? eventOption.color + "-U" : eventOption.color;
    return c3 + " " + c2;
  }, [machine, eventOption]);

  const resetForm = useCallback(() => {
    let active = document.querySelector(".editMode");
    active?.classList.remove("editMode");
    setPostData(initialPostData);
    setFiles([]);
    setPoster(null);
    setReset(true);
    setEventOption(prevOption => ({
      ...prevOption,
      color: null  // Set the 'color' property to null
    }))
  }, [setEventOption, setFiles, setPostData, setPoster, setReset]);

  useEffect(() => {
    const fetchPostData = async (id) => {
      try {
        const data = await getDataById(id);
        setPostData(data);
        setFecthed(data.PosterId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (editMode || isReadOnly) {
      fetchPostData(id);
    }
    //resetForm();
  }, [editMode, setPostData, id, isReadOnly]);

  useEffect(() => {
    if (eventOption && eventOption.color) {
      setEvent(true);
    }
  }, [setEvent, eventOption]);

  const postAction = (url, data) => {
    try {
      axios.post(url, data).then((response) => response);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setPostData((prevState) => ({ ...prevState, [name]: value }));
  };

  const closeLog = () => {
    setMachineId('');
    setLog(false);
    setMenuClicked(false);
    setisNavActive(false);
    setBacktoMenu(false);
    if (slackSearchResultsContext)
    {
      slackSearchResultsContext.fetchMachineLogs()
    }
  }

  const sendData = (e) => {
    e.preventDefault();

    const formData = new FormData();
    const fields = [
      "PostId",
      "Symptoms",
      "Observation",
      "Hypothesis",
      "Action",
      "Plan",
      "ImprovementProposition",
      "Comment",
      "Question",
    ];

    fields.forEach((field) => {
      formData.set(field, postData[field] ?? "");
    });

    files.forEach((file) => formData.append("files", file));

    let formDataHasData = 0;

    for (const pair of formData.entries()) {
      if (pair[1]) {
        formDataHasData++;
      }
    }
    formData.set("MachineId", machineId || machine.Id);
    formData.set("PartReplacement", checked ? "1" : "0");
    if (editMode) {
      if (poster == null) {
        setNoUserSelect(true);
        return;
      }
      deleted.forEach((file) => formData.append("EditedPictures", file));
      formData.set("PostId", id);
      formData.set("PosterId", poster.Id);

      postAction(
        `${AOO_API_HOST}/aoo-api/ProductionsLog/EditLogEntry`,
        formData
      );

      setEditMode(false);
    } else {
      if (formDataHasData >= 0) { // always True
        if (poster == null) {
          setNoUserSelect(true);
          return;
        } else {
          if (eventOption && eventOption.color && machine){
            formData.set("Status", eventOption.color);
            formData.set("IsUrgentStatus", eventOption.isUrgent ? "1" : "0");
            setEvent(false);
          }
  
          formData.set("PosterId", poster.Id);
          postAction(
            `${AOO_API_HOST}/aoo-api/ProductionsLog/PostLogEntry`,
            formData
          );
          SlackLocalStoreManager.getInstance().addMachinePost(machineId)
        }
      }
    }
    resetForm();
    closeLog();
  };

  const exitMode = (e) => {
    setEditMode(false);
  };
  
  return (
    <FormContainer style={editMode ? { border: "3px solid orange" } : {}}>
      {editMode && (
        <ExitEditMode
          onClick={(e) => {
            exitMode(e);
          }}
        >
          &#10006;
        </ExitEditMode>
      )}

      {isBrowser && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ActionContainerLeft contentPresent={!(comment || impr || question)}>
            <DraggableInput
              inputType="improvement"
              setIsCommentVisible={setComment}
              setIsImprovementVisible={setImpr}
              setIsQuestionVisible={setQuestion}
              isReadOnly={isReadOnly}
            />
            <DraggableInput
              inputType="question"
              setIsCommentVisible={setComment}
              setIsImprovementVisible={setImpr}
              setIsQuestionVisible={setQuestion}
              isReadOnly={isReadOnly}
            />
            <DraggableInput
              inputType="comment"
              setIsCommentVisible={setComment}
              setIsImprovementVisible={setImpr}
              setIsQuestionVisible={setQuestion}
              isReadOnly={isReadOnly}
            />
          </ActionContainerLeft>
        </div>
      )}
      <Form
        encType="multipart/form-data"
        method="post"
        onSubmit={(e) => {
          sendData(e);
        }}
      >
        <LeftContainer>
          <InputContainer>
            <Label htmlFor={"PartReplacement"}>
              <span>Part replacement</span>
              <Checkbox
                style={{ padding: "0", color: "#b9b9b9e3" }}
                name="PartReplacement"
                checked={checked}
                readOnly={isReadOnly}
                onChange={handleChecked}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Label>
          </InputContainer>
          {/* <MultilineInput/> */}
          <InputContainer>
            <Label htmlFor={"Symptoms"}>
              <Icons
                value={"symptoms"}
                height={30}
                width={35}
                color={"#ffffff"}
              />
              {isBrowser && <span>Symptoms</span>}
            </Label>
          
            <MultilineInput
              name="Symptoms"
              type="text"
              handleChange={handleChange}
              value={postData.Symptoms ?? ""}
              placeholder={isBrowser ? undefined : "Symptoms"}
              isReadOnly={isReadOnly}
            />
          </InputContainer>
          <InputContainer>
            <Label htmlFor={"Observation"}>
              <Icons
                value={"observation"}
                height={30}
                width={35}
                color={"#ffffff"}
              />
              {isBrowser && <span>Observation</span>}
            </Label>
            <MultilineInput
              name="Observation"
              type="text"
              handleChange={handleChange}
              value={postData.Observation ?? ""}
              placeholder={isBrowser ? undefined : "Observation"}
              isReadOnly={isReadOnly}
            />
          </InputContainer>
          <InputContainer>
            <Label htmlFor={"Hypothesis"}>
              <Icons
                value={"hypothesis"}
                height={30}
                width={35}
                color={"#ffffff"}
              />
              {isBrowser && <span>Hypothesis</span>}
            </Label>
            <MultilineInput
              name="Hypothesis"
              type="text"
              handleChange={handleChange}
              value={postData.Hypothesis ?? ""} 
              placeholder={isBrowser ? undefined : "Hypothesis"}
              isReadOnly={isReadOnly}
            />
          </InputContainer>
          <InputContainer>
            <Label htmlFor={"Action"}>
              <Icons
                value={"action"}
                height={30}
                width={35}
                color={"#ffffff"}
              />
              {isBrowser && <span>Immediate action</span>}
            </Label>
            <MultilineInput
              name="Action"
              type="text"
              handleChange={handleChange}
              value={postData.Action ?? ""} 
              placeholder={isBrowser ? undefined : "Action"}
              isReadOnly={isReadOnly}
            />
          </InputContainer>
          <InputContainer>
            <Label htmlFor={"Plan"}>
              <Icons value={"plan"} height={30} width={35} color={"#ffffff"} />
              {isBrowser && <span>Plan</span>}
            </Label>
            <MultilineInput
              name="Plan"
              type="text"
              handleChange={handleChange}
              value={postData.Plan ?? ""} 
              placeholder={isBrowser ? undefined : "Plan"}
              isReadOnly={isReadOnly}
            />
          </InputContainer>

          {(impr || !isBrowser) && (
            <InputContainer>
              <Label htmlFor={"ImprovementProposition"}>
                <Icons
                  value={"improvement"}
                  height={30}
                  width={35}
                  color={"#ffffff"}
                />
                {isBrowser && <span>Improvement</span>}
              </Label>
              <MultilineInput
                name="ImprovementProposition"
                type="text"
                handleChange={handleChange}  rows={4}
                value={postData.ImprovementProposition ?? ""}
                placeholder={isBrowser ? undefined : "Improvement Proposition"}
                isReadOnly={isReadOnly}
              />
            </InputContainer>
          )}
          {(comment || !isBrowser) && (
            <InputContainer>
              <Label htmlFor={"Comment"}>
                <Icons
                  value={"comment"}
                  height={30}
                  width={35}
                  color={"#ffffff"}
                />
                {isBrowser && <span>Comment</span>}
              </Label>
              <MultilineInput
                name="Comment"
                type="text"
                handleChange={handleChange}
                value={postData.Comment ?? ""}
                placeholder={isBrowser ? undefined : "Comment"}
                isReadOnly={isReadOnly}
              />
            </InputContainer>
          )}
          {(question || !isBrowser) && (
            <InputContainer>
              <Label htmlFor={"Question"}>
                <Icons
                  value={"question"}
                  height={30}
                  width={35}
                  color={"#ffffff"}
                />
                {isBrowser && <span>Question</span>}
              </Label>
              <MultilineInput
                name="Question"
                type="text"
                handleChange={handleChange}
                value={postData.Question ?? ""} 
                placeholder={isBrowser ? undefined : "Question"}
                isReadOnly={isReadOnly}
              />
            </InputContainer>
          )}
        </LeftContainer>
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <RightContainer>
            <InputContainer style={{ height: "100%", maxWidth: "300px" }}>
              <Uploader editMode={editMode} isReadOnly={isReadOnly} editImages={postData.Images} />
            </InputContainer>
          </RightContainer>
          <ActionContainerRight>
            {!isBrowser && content.length === 0 && <Clip />}
            <div>
              {event && (
               <ColorChange sideSize={24} colorChangeString={MachineColorChange}/>
              )}
            </div>
            <SimpleDialog
              key={`simple-dialog-${UserDialogMode.machineSearchFilter}`}
              isReadOnly={isReadOnly}
              setPoster={setPoster}
              poster={poster}
              fetched={fetched}
              mode={UserDialogMode.openedFromLogEntry}
            />
            {!isReadOnly && <ActionButton style={{ width: "unset" }}>
              <Icons value={"send"} height={20} width={25} color={"#ffffff"} />
            </ActionButton>}
          </ActionContainerRight>
        </div>
      </Form>
    </FormContainer>
  );
};

export default FormComponent;
