import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Done from "./Machine/donesvg";
import { prepWidgetData } from "../Data/MachinesData";
import { useMachinesContext } from "../../context/MachinesContext";
import ContextMenuComponent from "./ContextMenu/ContextMenuComponent";
import { AOO_API_HOST } from "../../env";
import { useCookies } from "react-cookie";
import FilterState from "./Filter";
import MachineList from "./Machine/MachineList";
import Log from "./Log/components/Log";
import { GlobalContext } from "../../context/GlobalContext";
import { LogProvider } from "../../context/LogContext";

import {
  BlocksGrid,
  Months,
  MainInfo,
  Separator,
  ListOfMachines,
  WidgetRedEmpty,
  WidgetYellowEmpty,
  WidgetBlueEmpty,
  MainContainer,
} from "./Machine/MachineStyles";
import PopUp from "./Alert/popup";
import { MachineColors } from "./Machine/enums";

function Widgets() {
  let MachinesContext = useMachinesContext();
  const [alert, setAlert] = useState(false);
  const { machine, setWorkers, failureReasons, setFailureReasons } = useContext(GlobalContext);

  useEffect(() => {
    const fetchWorkers = async () => {
      const workerUrl = AOO_API_HOST + "/aoo-api/Productions/get-workers";
      const workerResponse = await axios.get(workerUrl);
      setWorkers(workerResponse.data);
    };

    const fetchFailureReasons = async () => {
      const reasonUrl =
        AOO_API_HOST + "/aoo-api/Productions/get-failure-reasons";
      const reasonResponse = await axios.get(reasonUrl);
      setFailureReasons(reasonResponse.data);
    };

    fetchWorkers();
    fetchFailureReasons();
  }, []);

  const [cookies] = useCookies();
  const [cookieValue, setCookieValue] = useState("A");

  useEffect(() => {
    if (cookies.preferredState) {
      setCookieValue(cookies.preferredState);
    }
  }, [cookies.preferredState]);

  return (
    <MainContainer>
      <LogProvider>
        <Log />
      </LogProvider>
      {alert && (
        <PopUp
          setAlert={setAlert}
          machine={machine}
          reason={failureReasons}
          event={{}}
        />
      )}
      {window.innerWidth < 560 && <FilterState />}
      <BlocksGrid>
        {(() => {
          if (
            (window.innerWidth < 560 &&
              (cookieValue === "R" || cookieValue === "A")) ||
            window.innerWidth > 560
          ) {
            return (
              <Separator>
                <MainInfo>
                  <Months>Down</Months>
                </MainInfo>
                {(() => {
                  if (
                    prepWidgetData(MachinesContext.propData).redMachines
                      .length === 0
                  ) {
                    return (
                      <ListOfMachines>
                        <WidgetRedEmpty>
                          No Machines are stopped
                          <Done />
                        </WidgetRedEmpty>
                      </ListOfMachines>
                    );
                  } else {
                    return (
                      <MachineList
                        key={"list-red"}
                        data={
                          prepWidgetData(MachinesContext.propData).redMachines
                        }
                        background={MachineColors.Red}
                      />
                    );
                  }
                })()}
              </Separator>
            );
          }
        })()}
        {(() => {
          if (
            (window.innerWidth < 560 &&
              (cookieValue === "Y" || cookieValue === "A")) ||
            window.innerWidth > 560
          ) {
            return (
              <Separator>
                <MainInfo>
                  <Months>Faulty</Months>
                </MainInfo>

                {(() => {
                  if (
                    prepWidgetData(MachinesContext.propData).yellowMachines
                      .length === 0
                  ) {
                    return (
                      <ListOfMachines>
                        <WidgetYellowEmpty>
                          No Machines are slowed
                          <Done />
                        </WidgetYellowEmpty>
                      </ListOfMachines>
                    );
                  } else {
                    return (
                      <MachineList
                        data={
                          prepWidgetData(MachinesContext.propData)
                            .yellowMachines
                        }
                        key={"list-yellow"}
                        background={MachineColors.Yellow}
                      />
                    );
                  }
                })()}
              </Separator>
            );
          }
        })()}
        {(() => {
          if (
            (window.innerWidth < 560 &&
              (cookieValue === "B" || cookieValue === "A")) ||
            window.innerWidth > 560
          ) {
            return (
              <Separator>
                <MainInfo>
                  <Months>Needs a hug</Months>
                </MainInfo>

                {(() => {
                  if (
                    prepWidgetData(MachinesContext.propData).blueMachines
                      .length === 0
                  ) {
                    return (
                      <ListOfMachines>
                        <WidgetBlueEmpty>
                          No Machines are flagged
                          <Done />
                        </WidgetBlueEmpty>
                      </ListOfMachines>
                    );
                  } else {
                    return (
                      <MachineList
                        data={
                          prepWidgetData(MachinesContext.propData).blueMachines
                        }
                        background={MachineColors.Blue}
                        key={"list-blue"}
                      />
                    );
                  }
                })()}
              </Separator>
            );
          }
        })()}
      </BlocksGrid>

      <ContextMenuComponent/>
    </MainContainer>
  );
}

export default Widgets;
