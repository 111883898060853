
import MachineDash from "./Components/Machine/index";

function App() {
  return (
     <MachineDash/>
  );
}

export default App;
