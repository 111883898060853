import React, { useContext, useState, useEffect } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import {  NavLink,Switch } from "../NavbarStyles";
import { Container, Row,Inner,Title } from "./Styles";


import { AOO_API_HOST } from "../../../env";
import SearchBar from "../Components/SearchBar";
import Machine from "./OpenLogMachine";
import Routes from "../../Data/Routes";
export interface Machine {
  Id: String;
  MachineCode: String;
  MachineName: String;
  LastEntryDate: Date;
  isPinned: Boolean;
  IsUrgent: Boolean;
  EventState: String;
}

const Log = () => {
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [originalSearchResults, setOriginalSearchResults] = useState([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);
 
  let [isPinned, setIsPinned] = useState<boolean>(null);

  let handleClick = (e, machine) => {
    navigate(Routes.MainLogOptions, {
      state: { machine: machine },
    });
  };

  useEffect(() => {
    const fetchData = async (url) => {
      try {
        if (isPinned != null) {
          url = `${url}?ispinned=${isPinned}`;
        }

        const response = await axios.get(AOO_API_HOST + url);
        const data = response.data;
        setSearchResults(data);
        setOriginalSearchResults(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData("/aoo-api/ProductionsLog/PinnedMachines");
  }, [isPinned]);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    if (checked) {
      setSearchResults(
        originalSearchResults.filter((item) => item.isPinned === true)
      );
    } else {
      setSearchResults(originalSearchResults);
    }
  };

  return (
    <>
      <NavLink
        onClick={() => {
          navigate(Routes.Main);
        }}
        style={{ marginRight: 30 }}
      >
        Back to menu
      </NavLink>
      <Title>Machine Log Entry</Title>
      <Title>
        <SearchBar
          machines={originalSearchResults}
          setSearchResults={setSearchResults}
        />
      </Title>
      <Container>
        <Inner>
          {" "}
          <Title>
            Filter pinned machines
            <Switch style={{ marginBottom: "10px" }} key={"switch1"}>
              <input
                type="checkbox"
                id="switch"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="switch">Toggle</label>
            </Switch>
          </Title>
          <Row>
            {searchResults.map((machine, index) => {
              const className = machine.IsUrgent
                ? `${machine.EventState}-U machine`
                : `${machine.EventState} machine`;

              return (
                <Machine
                  handleClick={handleClick}
                  machine={machine}
                  key={index}
                  value={machine.MachineId}
                  className={className + " " + "act"}
                />
              );
            })}
          </Row>
        </Inner>
      </Container>
   
    </>
  );
};

export default Log;
