import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { isMobile } from "react-device-detect";
import type {MachineDto} from "../Models/LogSearchResultDto";
import {SlackViewMobileMachineCard} from "./SlackViewMobileMachineCard";
import SearchIcon from '@mui/icons-material/Search';
import {SlackViewUIContext} from "../Context/SlackViewUIContext";
import {GlobalContext} from "../../../context/GlobalContext";
import { useMachinesContext } from "../../../context/MachinesContext";
import { ReadMachine } from '../Utils/SlackLocalStoreManager';
import { MachinesGroupType } from "../Models/MachinesGroupType";

interface SlackViewMobileMachinesGroupProps {
    groupTitle: string,
    machineDto: { [key: string]: MachineDto[] },
    readMachines: ReadMachine[];
    setReadMachine: (id: string, value: boolean, postCount: number) => void;
    groupType: MachinesGroupType
}

export const SlackViewMobileMachinesGroup: React.FC<SlackViewMobileMachinesGroupProps> = (
    {
        groupTitle,
        machineDto,
        readMachines,
        setReadMachine,
        groupType,
    }) => {
    const DEFAULT_PAGE_SIZE = 15;
    const [visibleMachines, setVisibleMachines] = useState<number>(DEFAULT_PAGE_SIZE);
    const {isSearchOpen, setIsSearchOpen} = useContext(SlackViewUIContext);
    const {
        setMachineId,
        setLog,
        setShow,
        setGlobalPoints,
        setGlobalStatus,
        setMachine,
        setBacktoMenu
    } = useContext(GlobalContext);
    const machinesContext = useMachinesContext();
    const isInfiniteScrollPaginationEnabled = groupType === MachinesGroupType.PastEntries;

    const machines = useMemo(() => {
        if (!machineDto) return [];
        return Object.values(machineDto).flat();
    }, [machineDto]);

    const visibleMachinesList = useMemo(() => {
        return machines.slice(0, visibleMachines);
    }, [machines, visibleMachines]);

    const onSearchButtonClick = useCallback(() => {
        setIsSearchOpen(!isSearchOpen);
    }, [isSearchOpen, setIsSearchOpen])

    const openLog = useCallback((machineId: string) => {
        const machine = machines.find(machine=>machine.MachineId === machineId)

        setReadMachine(machineId, true, machine?.PostCount ?? 0);
        setMachineId(machineId);
        setLog(true);
        setBacktoMenu(true);
    }, [setBacktoMenu, setLog, setMachineId, setReadMachine]);

    const openContextMenu = (e:React.MouseEvent<HTMLDivElement, MouseEvent>, machineId: string) => {        
        e.preventDefault();
        
        const allMashines = Object.values(machinesContext.propData).flat();
        const machine =  allMashines.find(machine=>machine.Id===machineId)
        
        if (
            document.querySelector(".open-sub-menu") !== null &&
            document.querySelector(".open-sub-menu") !== undefined
        ) {
            document
            .querySelector(".open-sub-menu")
            .classList.remove("open-sub-menu");
        }
        setShow(true);
        setGlobalPoints({ x: e.pageX, y: e.pageY });
        setGlobalStatus({ color: machine?.EventState ?? 'G', isUrgent: machine.IsUrgent });
        setMachine(machine);
        setMachineId(machine.Id);
        
        if (isMobile) {
            document.documentElement.style.overflow = "hidden";
            // document.body.scroll = "no";
        }
    }

    const checkIfMachineIsRead = (machine: MachineDto) =>
      groupType === MachinesGroupType.YesterdayLogs &&
      !!readMachines.find(
        (rm) =>
          rm.machineId === machine.MachineId &&
          rm.postCount >= machine.PostCount
      );

    const RenderMachinesPaginated: React.FC = () => {
        return (
            <InfiniteScroll
                dataLength={visibleMachinesList.length}
                scrollableTarget={"machines-scrollable"}
                next={() => setVisibleMachines((prev) => Math.min(prev + DEFAULT_PAGE_SIZE, machines.length))}

                hasMore={visibleMachines < machines.length}
                loader={<></>}
            >
                {visibleMachinesList.map((m) => (
                    <div style={{width: "100%"}} key={m.MachineId} className="slack-mobile-machine-item">
                        <SlackViewMobileMachineCard onMachineRightClick={openContextMenu} onMachineLeftClick={openLog} groupType={groupType}
                                                    machine={m} isRead={checkIfMachineIsRead(m)} setReadMachine={setReadMachine}/>
                    </div>
                ))}
            </InfiniteScroll>
        );
    };

    const RenderMachinesAll: React.FC = () => {
        return (
            <>
                {machines.map((m) => (
                    <div style={{width: "100%"}} key={m.MachineId} className="slack-mobile-machine-item">
                        <SlackViewMobileMachineCard onMachineRightClick={openContextMenu} onMachineLeftClick={openLog} groupType={groupType}
                                                    machine={m} isRead={checkIfMachineIsRead(m)} setReadMachine={setReadMachine}/>
                    </div>
                ))}
            </>
        );
    };

    const RenderMachines: React.FC = () => {
        if (isInfiniteScrollPaginationEnabled)
            return <RenderMachinesPaginated/>;
        else
            return <RenderMachinesAll/>;
    };

    return (
        <div
            style={{
                marginTop: groupType === MachinesGroupType.YesterdayLogs ? "15px" : undefined,
                marginBottom: "15px",
                width: "100%",
                paddingBottom: "15px",
                borderBottom: "1px solid rgba(255, 255, 255, 0.50)",
                paddingLeft: "15px",
            }}
            className="slack-mobile-group-container"
        >
            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                width: '100%'
            }}>
                <h3
                    style={{
                        color: "#FFF",
                        fontFamily: "Lato",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: "700",
                    }}
                    className="slack-mobile-group-title"
                >
                    {groupTitle}
                </h3>
                {groupType === MachinesGroupType.YesterdayLogs && (
                    <SearchIcon style={{
                        color: 'white',
                        cursor: 'pointer',
                        borderRadius: '16px',
                        backgroundColor: '#A665A8',
                        height: '24px',
                        width: '24px',
                        padding: '1px',
                        marginLeft: '10px',
                    }}
                                onClick={onSearchButtonClick}/>
                )}
            </div>
            <div className="slack-mobile-group-content">
                <RenderMachines/>
            </div>
        </div>
    );
};
