// @ts-nocheck
import React, {
  useContext,
  useState,
  useRef,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { MachinesContext } from "../../../context/MachinesContext";
import { GlobalContext } from "../../../context/GlobalContext";
import { AOO_API_HOST } from "../../../env";
import MobileOptions from "../../Mobile/MobileOptions";
import CoverUploader from "../CoverUploader/CoverUploader";
import Dialog from "../Alert/Dialog";
import DaysToFix from "../Alert/DayToFix";
import { postAction } from "../WidgetActions";
import { 
  ContextMenuItem, 
  submenuBaseStyles, 
} from "./ContextMenu";
import EventMenuItem from "./EventMenuItem";
import Mark from "./Mark";
import SubMenu from "./SubMenu";
import { 
  useWorkerItems, 
  useInspectorItems, 
  useReasonItems, 
  useFixItems, 
  useCoverItems 
} from "./MenuItems";
import { 
  getSubmenuPosition, 
  getOptimalSubmenuPosition,
  type SpaceInfo,
  type SubmenuPosition,
  type SubmenuSize 
} from "./ContextMenuUtils";

export default function ContextMenuComponent() {
  // Refs for menu components
  const menuRef = useRef(null);
  const [root, setRoot] = useState();
  const [subStatusRoot, setsubStatusRoot] = useState();
  const [subWorkerRoot, setsubWorkerRoot] = useState();
  const [subReasonRoot, setsubReasonRoot] = useState();
  const [subFixRoot, setsubFixRoot] = useState();
  const [subInspectorRoot, setsubInspectorRoot] = useState();
  
  // Menu position state
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  
  // State for active submenu
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  // State for long press tracking
  const [touchTimer, setTouchTimer] = useState(null);
  const [touching, setTouching] = useState(false);

  const [isCoverPictureModalOpen, setIsCoverPictureModalOpen] = useState(false);
  const [isDaysToFixModalOpen, setIsDaysToFixModalOpen] = useState(false);
  const [isRemoveDaysToFixModalOpen, setIsRemoveDaysToFixModalOpen] =
    useState(false);

  const {
    machine,
    globalStatus,
    globalPoints,
    show,
    setShow,
    isActive: isMobileOptionsActive,
    setLog,
    isSlackViewActive,
    setEventOption,
    workers,
    failureReasons,
  } = useContext(GlobalContext);
  const { machineDtoMap } = useContext(MachinesContext);

  // Close menu and clear states
  const closeMenu = useCallback(() => {
    setShow(false);
    document.documentElement.style.overflow = "";
    document.body.scroll = "yes";
  }, [setShow]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShow(false);
        setActiveSubmenu(null);
      }
    };
    
    const handleTouchOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShow(false);
        setActiveSubmenu(null);
        
        // Clear touch timer
        if (touchTimer) {
          clearTimeout(touchTimer);
          setTouchTimer(null);
        }
        setTouching(false);
      }
    };

    document.addEventListener("click", handleClickOutside, false);
    document.addEventListener("touchstart", handleTouchOutside, false);
    
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
      document.removeEventListener("touchstart", handleTouchOutside, false);
    };
  }, [setShow, touchTimer]);

  // Function for handling touch start
  const handleTouchStart = (submenuType) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Start timer for long press detection
    const timer = setTimeout(() => {
      setActiveSubmenu(submenuType);
      setTouching(false);
    }, 300); 
    
    setTouchTimer(timer);
    setTouching(true);
  };
  
  // Function for handling touch end
  const handleTouchEnd = (submenuType) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Clear timer if touch ends
    if (touchTimer) {
      clearTimeout(touchTimer);
      setTouchTimer(null);
    }
    
    // If it was a short touch, toggle submenu
    if (touching) {
      if (activeSubmenu === submenuType) {
        setActiveSubmenu(null);
      } else {
        setActiveSubmenu(submenuType);
      }
      setTouching(false);
    }
  };
  
  // Function for handling touch cancel
  const handleTouchCancel = () => {
    if (touchTimer) {
      clearTimeout(touchTimer);
      setTouchTimer(null);
    }
    setTouching(false);
  };

  // React approach for toggling submenu
  const toggleSubmenu = (submenuType) => (e) => {
    e.stopPropagation();
    if (activeSubmenu === submenuType) {
      setActiveSubmenu(null);
    } else {
      setActiveSubmenu(submenuType);
    }
  };

  // Mouse hover handlers for desktop
  const handleMouseEnter = (submenuType) => (e) => {
    if (!isMobile) {
      setActiveSubmenu(submenuType);
    }
  };

  const handleMouseLeave = (submenuType) => (e) => {
    if (!isMobile) {
      // Only close if we're not entering the submenu itself
      const relatedTarget = e.relatedTarget;
      const submenuEl = getSubmenuRef(submenuType);
      
      if (submenuEl && !submenuEl.contains(relatedTarget)) {
        setActiveSubmenu(null);
      }
    }
  };

  const handleRemoveDisagree = () => {
    setIsRemoveDaysToFixModalOpen(false);
  };
  const handleRemoveAgree = () => {
    setIsRemoveDaysToFixModalOpen(false);
    postAction(
      AOO_API_HOST + `/aoo-api/Productions/remove-fix-days`,
      machine.EventId
    );
  };

  // Calculate menu position based on globalPoints and viewport constraints
  useLayoutEffect(() => {
    if (!menuRef.current || !globalPoints || !show) return;
    
    const calculatePosition = () => {
      const menuElement = menuRef.current;
      const menuRect = menuElement.getBoundingClientRect();
      const viewportWidth = window.visualViewport ? window.visualViewport.width : window.innerWidth;
      const viewportHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
      
      // Initial position from globalPoints
      let x = globalPoints.x;
      let y = globalPoints.y;
      
      // Adjust for right edge of screen
      if (x + menuRect.width > viewportWidth) {
        x = viewportWidth - menuRect.width - 5;
      }
      
      // Adjust for bottom edge of screen
      if (y + menuRect.height > viewportHeight) {
        y = viewportHeight - menuRect.height - 5;
      }
      
      // Ensure menu is not positioned off-screen
      x = Math.max(5, x);
      y = Math.max(5, y);
      
      setMenuPosition({ x, y });
    };
    
    // Initial calculation
    calculatePosition();
    
    // Recalculate on resize and orientation change
    const handleResize = () => calculatePosition();
    
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    
    if (window.visualViewport) {
      window.visualViewport.addEventListener('resize', handleResize);
      window.visualViewport.addEventListener('scroll', handleResize);
    }
    
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
      
      if (window.visualViewport) {
        window.visualViewport.removeEventListener('resize', handleResize);
        window.visualViewport.removeEventListener('scroll', handleResize);
      }
    };
  }, [globalPoints, show]);

  // Function to handle touch events for submenus
  const handleSubmenuTouch = (e) => {
    e.stopPropagation();
  };

  // Function to prevent page scrolling when user reaches the end of submenu
  const handleSubmenuScroll = (e) => {
    const { currentTarget } = e;
    if (
      (currentTarget.scrollTop === 0 && e.deltaY < 0) ||
      (currentTarget.scrollHeight - currentTarget.scrollTop === currentTarget.clientHeight && e.deltaY > 0)
    ) {
      e.preventDefault();
    }
    e.stopPropagation();
  };

  // Get the submenu reference based on type
  const getSubmenuRef = (submenuType) => {
    switch (submenuType) {
      case 'worker': return subWorkerRoot;
      case 'reason': return subReasonRoot;
      case 'fix': return subFixRoot;
      case 'status': return subStatusRoot;
      case 'inspector': 
      case 'assign-inspector': 
        return subInspectorRoot;
      default: return null;
    }
  };

  // Calculate submenu styles using unified positioning functions
  const getSubmenuStyle = (submenuType) => {
    if (!menuRef.current) return {};
    
    const rootRect = menuRef.current.getBoundingClientRect();
    const submenuRef = getSubmenuRef(submenuType);
    const size = isMobile ? 'mobile' : 'desktop';
    
    // Calculate available space around the menu
    const spaceInfo: SpaceInfo = {
      spaceRight: window.innerWidth - rootRect.right,
      spaceLeft: rootRect.left,
      spaceBelow: window.innerHeight - rootRect.top,
      spaceAbove: rootRect.bottom,  
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    };
    
    // Determine the optimal position for the submenu
    const position = getOptimalSubmenuPosition(rootRect, spaceInfo, submenuRef, size);
    
    // Get position styles from utility function
    const positionStyle = getSubmenuPosition(position, rootRect, spaceInfo, size);
    
    // Add base styles depending on device type
    const baseStyles = isMobile 
      ? submenuBaseStyles.mobile 
      : submenuBaseStyles.desktop;
    
    return {
      ...positionStyle,
      ...baseStyles
    };
  };

  const changeEventStatus = async (option: any) => {
    try {
      await postAction(`${AOO_API_HOST}/aoo-api/Productions/post-status-urgency`, {
        MachineId: machine.Id,
        Status: option.color,
        IsUrgent: option.isUrgent,
      });
      closeMenu(); // Close the menu after action
    } catch (error) {
      console.error(error);
      closeMenu(); // Also close on error
    }
  };

  // Common props for all submenu components
  const submenuProps = {
    onSubmenuTouch: handleSubmenuTouch,
    onSubmenuScroll: handleSubmenuScroll,
    onTouchCancel: handleTouchCancel
  };

  // Generate menu items for worker submenu
  const workerItems = useWorkerItems(workers, machine?.Id, closeMenu);
  
  // Generate menu items for inspector submenu
  const inspectorItems = useInspectorItems(workers, machine?.Id, closeMenu);
  
  // Generate menu items for reason submenu
  const reasonItems = useReasonItems(
    failureReasons, 
    machine?.EventId, 
    machine?.EventState, 
    closeMenu
  );
  
  // Generate menu items for fix days submenu
  const fixItems = useFixItems(
    machine?.DaysToFix != null,
    () => setIsDaysToFixModalOpen(true),
    () => setIsRemoveDaysToFixModalOpen(true),
    closeMenu
  );
  
  // Generate menu items for cover picture submenu
  const coverItems = useCoverItems(
    machine?.Cover != null,
    machine?.Id,
    () => setIsCoverPictureModalOpen(true),
    closeMenu
  );

  return (
    <>
      {isMobileOptionsActive && (
        <MobileOptions
          id={machine.Id}
          status={globalStatus}
          machine={machine}
          setInput={setIsDaysToFixModalOpen}
          setRemove={setIsRemoveDaysToFixModalOpen}
          setCover={setIsCoverPictureModalOpen}
        />
      )}
      {isCoverPictureModalOpen && (
        <CoverUploader
          setCover={setIsCoverPictureModalOpen}
          remove={isCoverPictureModalOpen}
        />
      )}

      <DaysToFix
        input={isDaysToFixModalOpen}
        handleClose={() => setIsDaysToFixModalOpen(false)}
        machine={machine}
      />
      <Dialog
        machine={machine}
        remove={isRemoveDaysToFixModalOpen}
        handleDialogDisagree={handleRemoveDisagree}
        handleRemoveAgree={handleRemoveAgree}
      />

      {show ? (
        <ContextMenuItem 
          ref={(ref) => {setRoot(ref); menuRef.current = ref;}}
          style={{
            position: 'absolute',
            left: `${menuPosition.x}px`,
            top: `${menuPosition.y}px`,
            maxHeight: isMobile ? '80vh' : 'auto',
            overflowY: isMobile ? 'auto' : 'visible'
          }}
        >
          <ul>
            <Mark
              machine={machine}
              setsubStatusRoot={setsubStatusRoot}
              setEventOption={setEventOption}
              color={globalStatus.color}
            />
            <EventMenuItem
              setsubStatusRoot={setsubStatusRoot}
              onOptionClick={changeEventStatus}
              activeSubmenu={activeSubmenu}
              onToggle={toggleSubmenu}
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              onTouchCancel={handleTouchCancel}
              submenuStyle={getSubmenuStyle}
              onSubmenuTouch={handleSubmenuTouch}
              onSubmenuScroll={handleSubmenuScroll}
              onMouseEnter={handleMouseEnter('status')}
              onMouseLeave={handleMouseLeave('status')}
            />
            {machine.Workers && machine.Workers.length > 0 ? (
              <>
                <SubMenu
                  items={workerItems}
                  type="worker"
                  title="Change worker"
                  isActive={activeSubmenu === 'worker'}
                  rootRef={(ref) => setsubWorkerRoot(ref)}
                  style={getSubmenuStyle('worker')}
                  onToggle={toggleSubmenu('worker')}
                  onTouchStart={handleTouchStart('worker')}
                  onTouchEnd={handleTouchEnd('worker')}
                  onMouseEnter={handleMouseEnter('worker')}
                  onMouseLeave={handleMouseLeave('worker')}
                  {...submenuProps}
                />
                <li
                  className={"menu-item worker"}
                  onClick={() => {
                    postAction(
                      AOO_API_HOST + `/aoo-api/Productions/remove-worker`,
                        machine.Id
                    );
                    setShow(false);
                  }}
                >
                  Remove worker
                </li>
              </>
            ) : (
              <SubMenu
                items={workerItems}
                type="worker"
                title="Assign a worker"
                isActive={activeSubmenu === 'worker'}
                rootRef={(ref) => setsubWorkerRoot(ref)}
                style={getSubmenuStyle('worker')}
                onToggle={toggleSubmenu('worker')}
                onTouchStart={handleTouchStart('worker')}
                onTouchEnd={handleTouchEnd('worker')}
                onMouseEnter={handleMouseEnter('worker')}
                onMouseLeave={handleMouseLeave('worker')}
                {...submenuProps}
              />
            )}

            {machineDtoMap[machine.Id]?.InspectorId ? (
                <>
                  <SubMenu
                    items={inspectorItems}
                    type="inspector"
                    title="Change inspector"
                    isActive={activeSubmenu === 'inspector'}
                    rootRef={(ref) => setsubInspectorRoot(ref)}
                    style={getSubmenuStyle('inspector')}
                    onToggle={toggleSubmenu('inspector')}
                    onTouchStart={handleTouchStart('inspector')}
                    onTouchEnd={handleTouchEnd('inspector')}
                    onMouseEnter={handleMouseEnter('inspector')}
                    onMouseLeave={handleMouseLeave('inspector')}
                    {...submenuProps}
                  />
                  <li
                      className={"menu-item"}
                      onClick={() => {
                        try {
                          postAction(
                              AOO_API_HOST +
                              `/aoo-api/Productions/resolve-inspection-flag`,
                              machine.Id
                          );
                        } catch (error) {
                          console.log(error);
                        }
                        setShow(false);
                      }}
                  >
                    Close Inspection Flag
                  </li>
                </>
            ) : (
                <SubMenu
                  items={inspectorItems}
                  type="inspector"
                  title="Assign inspector"
                  isActive={activeSubmenu === 'assign-inspector'}
                  rootRef={(ref) => setsubFixRoot(ref)}
                  style={getSubmenuStyle('assign-inspector')}
                  onToggle={toggleSubmenu('assign-inspector')}
                  onTouchStart={handleTouchStart('assign-inspector')}
                  onTouchEnd={handleTouchEnd('assign-inspector')}
                  onMouseEnter={handleMouseEnter('assign-inspector')}
                  onMouseLeave={handleMouseLeave('assign-inspector')}
                  {...submenuProps}
                />
            )}

            <SubMenu
              items={reasonItems}
              type="reason"
              title="Assign an action"
              isActive={activeSubmenu === 'reason'}
              rootRef={(ref) => setsubReasonRoot(ref)}
              style={getSubmenuStyle('reason')}
              onToggle={toggleSubmenu('reason')}
              onTouchStart={handleTouchStart('reason')}
              onTouchEnd={handleTouchEnd('reason')}
              onMouseEnter={handleMouseEnter('reason')}
              onMouseLeave={handleMouseLeave('reason')}
              {...submenuProps}
            />

            <SubMenu
              items={fixItems}
              type="fix"
              title="Days to fix"
              isActive={activeSubmenu === 'fix'}
              rootRef={(ref) => setsubFixRoot(ref)}
              style={getSubmenuStyle('fix')}
              onToggle={toggleSubmenu('fix')}
              onTouchStart={handleTouchStart('fix')}
              onTouchEnd={handleTouchEnd('fix')}
              onMouseEnter={handleMouseEnter('fix')}
              onMouseLeave={handleMouseLeave('fix')}
              {...submenuProps}
            />

            <SubMenu
              items={coverItems}
              type="cover"
              title="Cover picture"
              isActive={activeSubmenu === 'cover'}
              rootRef={(ref) => setsubFixRoot(ref)}
              style={getSubmenuStyle('cover')}
              onToggle={toggleSubmenu('cover')}
              onTouchStart={handleTouchStart('cover')}
              onTouchEnd={handleTouchEnd('cover')}
              onMouseEnter={handleMouseEnter('cover')}
              onMouseLeave={handleMouseLeave('cover')}
              {...submenuProps}
            />

            {!isSlackViewActive && (
              <li
                className={"menu-item log"}
                onClick={() => {
                  setLog(true);
                  setShow(false);
                }}
              >
                Log
              </li>
            )}
          </ul>
        </ContextMenuItem>
      ) : (
        null
      )}
    </>
  );
}
