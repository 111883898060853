import { useState, useCallback, useRef, useEffect } from 'react';
import { isGuid } from '../Utils/Guid';

interface UseSearchInputProps {
    initialValue?: string;
    onSearch: (value: string) => Promise<void>;
    debounceMs?: number;
    machineName?: string | null;
}

export const useSearchInput = ({
                                   initialValue = '',
                                   onSearch,
                                   debounceMs = 500,
                                   machineName = null,
                               }: UseSearchInputProps) => {
    const [inputValue, setInputValue] = useState(initialValue);
    const timeoutRef = useRef<NodeJS.Timeout>();
    const abortControllerRef = useRef<AbortController | null>(null);

    // Clean up on unmount
    useEffect(() => {
        return () => {
            timeoutRef.current && clearTimeout(timeoutRef.current);
            abortControllerRef.current?.abort();
        };
    }, []);

    // Update input value when machine name is available
    useEffect(() => {
        if (machineName) {
            setInputValue(machineName);
        }
    }, [machineName]);

    const handleSearch = useCallback(async (searchValue: string) => {
        // Abort previous request if any
        abortControllerRef.current?.abort();
        abortControllerRef.current = new AbortController();

        try {
            await onSearch(searchValue);
        } catch (error) {
            if (!abortControllerRef.current?.signal.aborted) {
                console.error('Search error:', error);
            }
        }
    }, [onSearch]);

    const handleInputChange = useCallback((value: string) => {
        // Don't update the input if it's a GUID - wait for the name to come back
        if (isGuid(value)) {
            handleSearch(value);
            return;
        }

        setInputValue(value);

        // Debounce the search
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            handleSearch(value);
        }, debounceMs);
    }, [handleSearch, debounceMs]);

    const clearInput = useCallback(() => {
        setInputValue('');
        handleSearch('');
    }, [handleSearch]);

    return {
        inputValue,
        handleInputChange,
        clearInput,
    };
};
