import React, { useContext } from "react";
import { isMobile } from "react-device-detect";

import { SlackViewMobileMachinesGroup } from "./SlackViewMobileMachinesGroup";
import { SlackSearchResultsContext } from "../Context/SlackSearchResultsContext";
import Log from "../../Widgets/Log/components/Log";
import { GlobalContext } from "../../../context/GlobalContext";
import ContextMenuComponent from "../../Widgets/ContextMenu/ContextMenuComponent";
import SlackViewMobileSearchBar from "./SlackViewMobileSearchBar";
import { ReadMachine } from "../Utils/SlackLocalStoreManager";
import { MachinesGroupType } from "../Models/MachinesGroupType";

interface SlackViewMobileMachinesContainerProps {
    onSearchButtonClick: () => void;
    readMachines: ReadMachine[];
    setReadMachine: (id: string, value: boolean, postCount: number) => void;

}

export const SlackViewMobileMachinesContainer: React.FC<SlackViewMobileMachinesContainerProps> = (
    {
        onSearchButtonClick, readMachines, setReadMachine
    }
) => {
    const {log} = useContext(GlobalContext);
    const { searchedMachinesFiltered } = useContext(SlackSearchResultsContext);
    return (
        <div className="slack-mobile-machines-container"
             style={{
                 display: 'flex',
                 flexDirection: 'row',
                 minHeight: '100vh',
                 width: '100%',
                 minWidth: !isMobile ? '500px' : undefined,
                 backgroundColor: '#5E365F',
        }}>

            {searchedMachinesFiltered && (
                <div
                    id={"machines-scrollable"} //this is required to pass scrolling context to infinite scroll
                    style={{
                        flexBasis: !isMobile ? '500px' : undefined,
                        minWidth: 'min-content',
                        maxWidth: !isMobile ? '500px' : undefined,
                    width: '100%',
                    height: '100vh',
                    overflowY: 'scroll',
                }}
                >
                    <SlackViewMobileSearchBar/>
                    <SlackViewMobileMachinesGroup
                        groupTitle="Since yesterday"
                        machineDto={searchedMachinesFiltered.YesterdayLogs}
                        readMachines={readMachines}
                        setReadMachine={setReadMachine}
                        groupType={MachinesGroupType.YesterdayLogs}
                    />
                    <SlackViewMobileMachinesGroup
                        groupTitle="Since last week"
                        machineDto={searchedMachinesFiltered.LastWeekLogs}
                        readMachines={readMachines}
                        setReadMachine={setReadMachine}
                        groupType={MachinesGroupType.LastWeekLogs}
                    />
                    <SlackViewMobileMachinesGroup
                        groupTitle="Since last month"
                        machineDto={searchedMachinesFiltered.LastMonthLogs}
                        readMachines={readMachines}
                        setReadMachine={setReadMachine}
                        groupType={MachinesGroupType.LastMonthLogs}
                    />
                    <SlackViewMobileMachinesGroup
                        groupTitle="Past entries"
                        machineDto={searchedMachinesFiltered.PastLogs}
                        readMachines={readMachines}
                        setReadMachine={setReadMachine}
                        groupType={MachinesGroupType.PastEntries}
                    />
                </div>

            )}
            {log && (<Log/>)}

            <ContextMenuComponent />
        </div>
    );
};
