import React from "react";

import { ReactComponent as QrCodeScannerIcon } from "../../../assets/icons/qr-code-scan-icon.svg";

interface ScanButtonProps {
  onClick: () => void;
  background?: string;
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
}

export const ScanButton: React.FC<ScanButtonProps> = ({
  onClick,
  background = "black",
  width = "48px",
  height = "48px",
  padding,
  margin
}) => {
  return (
    <div
      className="pointer"
      style={{
        display: "flex",
        width: width,
        minWidth: width,
        height: height,
        padding: padding,
        margin: margin,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        background: background,
      }}
      onClick={onClick}
    >
      <QrCodeScannerIcon />
    </div>
  );
};
