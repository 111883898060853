import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { RemoteScanQRCodeWrapper } from "./RemoteScanQRCodeWrapper";
import { NavLink, Title, Content, QrCodeScannerComponentWrapper } from "../NavbarStyles";
import { GlobalContext } from "../../../context/GlobalContext";
import { useMachinesContext } from "../../../context/MachinesContext";
import { AOO_API_HOST } from "../../../env";
import { postAction } from "../../Widgets/WidgetActions";
import Reason from "./Reason";
import FinalOption from "./FinalOption";
import SearchBar from "../Components/SearchBar";
import MachineTile from "../Components/Machine";
import Routes from "../../Data/Routes";

enum PromptResult {
    NoAction,
    ChangeColor,
    GoToLog,
}

export const ScanQrCode = () => {
    const navigate = useNavigate();
    const machinesContext = useMachinesContext();
    const { machineId } = useParams();
    const [result, setResult] = useState("");
    const [isPromptActionOpened, setIsPromptActionOpened] = useState<boolean>(false);
    const [promptResultValue, setPromptResultValue] = useState<PromptResult>(PromptResult.NoAction);
    const [selectedMachine, setSelectedMachine] = useState({
        EventId: undefined,
        EventState: undefined
    });

    const [searchResults, setSearchResults] = useState([]);
    const [fetched, setFetched] = useState("");
    const [isLoading, setLoading] = useState(false);
    const { state } = useLocation();
    const [isSearchEmpty, setIsSearchEmpty] = useState(true);

    const {
        setMachineId,
        setLog,
        setMenuClicked,
        setisNavActive,
        isNavActive,
        setBacktoMenu,
        eventOption,
        setMachine,
    } = useContext(GlobalContext);

    const showFinalOption = promptResultValue === PromptResult.ChangeColor && selectedMachine !== undefined && eventOption?.color === ''

    const handleSearchChange = (inputValue) => {
        const trimmedInputValue = inputValue.trim();
        setIsSearchEmpty(trimmedInputValue === '');
    };

    const handleClick = (e, machine) => {
        setResult(machine.MachineId);
        loadMachineInspection(machine.MachineId);
        setIsPromptActionOpened(true);
    };

    const getMachineFromContext = useCallback(async (machineColor: string, machineId: string) => {
        const colorMap = {
            'Y': 'YellowMachines',
            'R': 'RedMachines',
            'B': 'BlueMachines',
            'G': 'GreenMachines'
        };
        return machinesContext.propData[colorMap[machineColor]]?.find(x => x.Id === machineId);
    }, [machinesContext.propData]);

    const loadMachineInspection = useCallback(async (machineId: string, retry: number = 3) => {
        const url = `${AOO_API_HOST}/aoo-api/Productions/GetMachine?machineId=${machineId}`;
        try {
            const response = await axios.get(url);
            if (response.status === 200) {
                const machineFromContext = await getMachineFromContext(response.data.EventState, machineId);
                if (!machineFromContext && retry > 0) {
                    return setTimeout(() => loadMachineInspection(machineId, retry - 1), 450);
                }
                setMachine(machineFromContext);
                setSelectedMachine(response.data);
            }
        } catch (error) {
            console.error("Error loading machine inspection:", error);
        }
    }, [setMachine, getMachineFromContext]);

    const openLog = useCallback(() => {
        if (result) {
            setMachineId(result);
            setLog(true);
            setMenuClicked(false);
            setisNavActive(false);
            setBacktoMenu(true);
            navigate(Routes.MainLog);
        }
    }, [result, navigate, setMachineId, setLog, setMenuClicked, setisNavActive, setBacktoMenu]);

    const onFinalOptionClick = (option) => {
      try {
        postAction(`${AOO_API_HOST}/aoo-api/Productions/post-status-urgency`, {
          MachineId: result,
          Status: option.color,
          IsUrgent: option.isUrgent,
        });
      } catch (error) {
        console.error(error);
      }
  
      setMenuClicked(false);
      setisNavActive(false);
      navigate(Routes.Main);
    };

    const onReasonClick = useCallback(async (reasonId: string) => {
        await postAction(
            `${AOO_API_HOST}/aoo-api/Productions/post-failure-reason`,
            {
                EventId: selectedMachine.EventId,
                ReasonId: reasonId,
                Status: selectedMachine.EventState,
            }
        );
        openLog();
    }, [openLog, selectedMachine]);

    const handleScanSuccess = useCallback((scannedResult: string) => {
        setResult(scannedResult);
        loadMachineInspection(scannedResult);
        setIsPromptActionOpened(true);
    }, [loadMachineInspection]);

    const setUserPromptResult = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const userPromptResult: PromptResult = parseInt(event.target.value);
        if (promptResultValue !== userPromptResult) {
            setPromptResultValue(userPromptResult);
            setIsPromptActionOpened(false);
        }
    }, [promptResultValue]);

    useEffect(() => {
        if (promptResultValue !== PromptResult.NoAction && result) {
            if (promptResultValue === PromptResult.GoToLog) {
                openLog();
            }
        }
    }, [result, promptResultValue, openLog]);

    useEffect(() => {
        if (machineId) {
            setResult(machineId);
            loadMachineInspection(machineId);
            setIsPromptActionOpened(true);
            setisNavActive(true);
        }
    }, [loadMachineInspection, machineId, setisNavActive]);

    useEffect(() => {
        if (!isLoading) {
            const url = `${AOO_API_HOST}/aoo-api/Productions/all-machines`;
            axios.get(url).then((response) => {
                setFetched(response.data);
                setSearchResults(response.data);
            });
            setLoading(true);
        }
    }, [state, isLoading]);

    const renderActionPrompt = () => (
        <QrCodeScannerComponentWrapper>
            <div style={{ display: 'flex', flexDirection: 'column', color: 'white', paddingTop: '15px' }}>
                <p>What do you want to do with scanned machine?</p>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={PromptResult.NoAction}
                    value={promptResultValue}
                    onChange={setUserPromptResult}
                    name="radio-buttons-group"
                >
                    <FormControlLabel value={PromptResult.ChangeColor} control={<Radio />} label="Change color" />
                    <FormControlLabel value={PromptResult.GoToLog} control={<Radio />} label="Go to log" />
                </RadioGroup>
            </div>
            {eventOption?.color?.length > 0 && (
                <Reason onReasonClick={onReasonClick} />
            )}
        </QrCodeScannerComponentWrapper>
    );

    return (
        <>
            <NavLink onClick={() => navigate(Routes.Main)}>
                Back to menu
            </NavLink>
            <Title>
                <SearchBar
                    machines={fetched}
                    setSearchResults={setSearchResults}
                    onSearchChange={handleSearchChange}
                />
            </Title>
            {showFinalOption && (
                <FinalOption
                    onFinalClick={onFinalOptionClick}
                    machine={selectedMachine}
                    includeBackButton={false}
                />
            )}
            {isSearchEmpty ? (
                <>
                    {!isPromptActionOpened && !result && (
                        <RemoteScanQRCodeWrapper
                            onScanSuccess={handleScanSuccess}
                            isEnabled={isNavActive && isSearchEmpty}
                        />
                    )}
                    {isPromptActionOpened && renderActionPrompt()}
                </>
            ) : (
                <>
                    <Title>Select machine</Title>
                    <Content style={{
                        width: "750px",
                        flexDirection: "column",
                        flexWrap: "unset",
                        justifyContent: "space-between"
                    }}>
                        {searchResults.map((machine, key) => (
                            <MachineTile
                                handleClick={handleClick}
                                machine={machine}
                                key={key}
                                value={machine.MachineId}
                            />
                        ))}
                    </Content>
                    {isPromptActionOpened && renderActionPrompt()}
                </>
            )}
        </>
    );
};
