// UIContext.tsx
import React, { createContext, useState } from 'react';

type ISlackViewUIContext =  {
    isSearchOpen: boolean;
    setIsSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SlackViewUIContext = createContext<ISlackViewUIContext | undefined>(undefined);

export const SlackViewUIContextProvider = ({ children }) => {
    const [isSearchOpen, setIsSearchOpen] = useState(true);

    return (
        <SlackViewUIContext.Provider value={{ isSearchOpen, setIsSearchOpen }}>
            {children}
        </SlackViewUIContext.Provider>
    );
};
