const Routes = {
  Main: "/main",

  MainSetFlagGroup: "/main/setflag/group",
  MainSetFlagWorker: "/main/setflag/worker",
  MainSetFlagResult: "/main/setflag/result",
  MainSetFlagMachine: "/main/setflag/machine",

  MainRemote: "/main/remote",
  MainRemoteGroup: "/main/remote/group",
  MainRemoteMachine: "/main/remote/machine",
  MainRemoteFinal: "/main/remote/final",
  MainRemoteReason: "/main/remote/reason",
  MainRemoteScanQrCode: "/main/remote/scanQrCode",

  MainAvailability: "/main/availability",
  MainAvailabilityManage: "/main/availability/manage",

  MainLog: "/main/log",
  MainLogOptions: "/main/log/options",

  MainInspection: "/main/inspection",
  MainResolved: "/main/resolved",
  MainBreakdownReport: "/main/breakdownReport",
  MainDialyLossReport: "/main/dailyLossReport",
};

export default Routes;
