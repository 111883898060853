import React from "react";
import {SlackViewMobile} from "../SlackViewMobile/SlackViewMobile";

export const SlackViewDesktop = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
        }}>
                <SlackViewMobile/>
        </div>
    );
};
