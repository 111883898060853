import styled, {keyframes} from "styled-components";
import { isBrowser, isMobile } from "react-device-detect";
import FileType from "../api/FileTypeEnum";

export const Overlay = styled.div`
  display: ${(props) => props.display};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  position: ${(props) => props.position}; /*this is needed due to overlay mechanic*/
  z-index: 5; /*might be need to be corrected*/
  flex: 1;
`;
export const ExitEditMode = styled.button`
  border-radius: 50%;
  font-size: 20px;
  background: #41464d;
  position: absolute;
  width: 30px;
  height: 30px;
  top: -10px;
  right: -10px;
  border: none;
  cursor: pointer;
  color: #fff;
  line-height: 30px;
  color: orange;
  @media (max-width: 768px) {
    top: 20px;
    right: 5px;

    background: none;
  }
`;
export const CloseButton = styled.button`
  border-radius: 50%;
  font-size: 30px;
  background: #41464d;
  position: absolute;
  width: 40px;
  height: 40px;
  right: -5px;
  border: none;
  top: -5px;
  cursor: pointer;
  color: #fff;
  @media (max-width: 768px) {
    top: 10px;
    right: 5px;
    color: white;
    background: none;
  }
`;

export const Dialog = styled.div`
  width: 100%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #1a1d21;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  @media (max-width: 768px) {
    // margin-top: 10vh;
    width: 100%;
    height: 95vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const Cover = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  margin-bottom: 30px;
  color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 5px;
    min-height: fit-content;
  }
`;

export const Header = styled.div`
  font-size: 15px;
  width: 100%;  
  display: flex;
  flex-direction: row;
  @media screen and (min-width: 2000) {
    font-size: 35px;
  }
`;

export const PostContainer = styled.div`
  min-height: 120px;
  background: #222529;
  border: 1px solid #80808026;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;
  @media (max-width: 768px) {
    min-height: 150px;
  }

  &.editMode {
    border: 3px solid orange;
  }
`;

export const ScrollContainer = styled.div`
  overflow: auto;
  // max-height: 300px;
  height: 100%;  
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

export const PostItem = styled.div`
  background: #222529;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;
export const SimpleContainer = styled.div`
  @media (max-width: 768px) {
    margin: 0 5px;
  }
`;

export const Poster = styled.div`
  padding: 2px;
  display: flex;
  align-items: center;
  font-size: 25px;
  margin-bottom: 10px;
  img {
    width: 30px;
  }

  p {
    margin-left: 10px;
    color: white;
  }
  @media screen and (min-width: 2000) {
    font-size: 35px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 8px;
  }
`;

export const PostImageToolTip = styled.div`
    display: flex;
    position: relative;
    background: rgb(26, 29, 33);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(128, 128, 128, 0.15);
    border-radius: 5px;
    height: 100%;
    padding: 0 10px 10px 10px;
`

export const Line = styled.div`
  display: flex;
  color: white;
  font-size: 15px;
  padding-left: 50px;
  margin-bottom: 5px;
  p {
    color: white;
    font-size: 25px;
    margin:0 5px;
  }
  @media screen and (min-width: 2000) {
    p {
      color: white;
      font-size: 35px;
      margin-left: 5px;
    }
  }
  @media (max-width: 768px) {
    p {
      font-size: 18px;
    }
    width: 100%;
  }

  @media (max-width: 420px) {
    font-size: 18px;

    p {
      font-size: 18px;
    }
  }
`;

export const ActionButton = styled.button`
  // width: 45px;
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;
  img {
    width: 100%;
    border-radius: 50%;
    padding: 2px;
  }
`;

export const ColorTransition = styled.div`
  &.yellow {
  }
  &.red {
  }
  &.green {
  }
  &.yellow {
  }
`;
export const Time = styled.span`
  color: gray;
  margin-left: 10px;
`;

//add position fixed
export const Date = styled.span`
  background: rgb(34, 37, 41);
  font-size: 25px;
  display: inline-block;
  height: 50px;
  margin-bottom: -15px;
  border-radius: 15px;
  border: 1px solid rgba(128, 128, 128, 0.15);
  color: white;
  padding: 12px;
  z-index: 99;
  @media (max-width: 768px) {
    font-size: 14px;
    height: unset;
  }
`;
export const FormContainer = styled.div`
  padding: 10px 0;
  background: #222529;
  border-radius: 8px;
  border: 1px solid #80808026;
  display: flex;
  align-items: ${isMobile ? "start" : "center"}
  margin-top: 15px;
  position: relative;
  height: ${isMobile ? "100%" : "unset"}
`;

export const LeftContainer = styled.div`
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
    // height: 225px;
    overflow: scroll;
    // padding-right: 10px;
  }
`;
export const RightContainer = styled.div`
  // width: 20%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-right: 10px;
  }
`;

export const Label = styled.label`
  width: 335px;
  display: flex;
  font-size: 25px;
  align-items: center;
  margin-bottom: 5px;
  @media screen and (min-width: 2000) {
    font-size: 35px;
  }
  @media (max-width: 560px) {
    width: unset;
    padding: 0 5px;
    // width: 235px;
    font-size: 15px;
  }
  // justify-content: space-between;;
  img {
    margin-right: 5px;
  }
  span {
    margin-left: 10px;
    color: #b4b4b4;
  }

  &.draggable {
    width: unset;
    justify-content: center;
  }
`;
export const Input = styled.input`

  margin-bottom: 2px;
  height: 40px;
  width: 100%;
  background: #1a1d21;
  outline: none;
  border-radius: 8px;
  border: 1px solid #80808026;
  margin-left: 20px;
  font-size: 20px;
  color: #b4b4b4;
  padding: 11px 10px;
  @media (max-width: 768px) {
    margin-left: 0px;
  }

  @media (max-width: 420px) {
    height: 30px;
    font-size: 18px;
  }
  @media screen and (min-width: 2000) {
    font-size: 30px;
  }
`;

export const TextArea = styled.textarea`
  margin-bottom: 2px;
  height: 40px;
  width: 100%;
  background: #1a1d21;
  outline: none;
  border-radius: 8px;
  border: 1px solid #80808026;
  margin-left: 20px;
  overflow: hidden;  
  font-size: 20px;
  color: #b4b4b4;
  padding: 11px 10px;
  @media (max-width: 768px) {
    margin-left: 0px;
  }

  @media (max-width: 420px) {
    height: 30px;
    font-size: 18px;
  }
  @media screen and (min-width: 2000) {
    font-size: 30px;
  }
`;
export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  &.draggable {
    justify-content: center;
  }
`;

export const ActionContainerLeft = styled.div`
  width: 65px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: ${(props) => props.contentPresent ? '1px solid #303336' : 'none'};
  background-color: #1a1d21;
  border-radius: 7px;
  height: min-content;

  svg{
    margin 5px 0;
  }
`;

export const ActionContainerRight = styled.div`
  width: 65px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & div {
    padding: 2px 0;
  }
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: end;
    // width: 100%;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 10px;
  @media (max-width: 768px) {
    width: 210px;
    margin: 5px;
  }
`;
export const PictureSmall = styled.div`
  background: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  position: relative;
  width: auto;
  margin: 5px;
  height: 60px;
  border: 1px solid white;

  .removeFileButton{
    border-radius: 50%;
    background: #41464d;
    position: absolute;
    width: 30px;
    height: 30px;
    right: -15px;
    border: none;
    top: -15px;
    line-height: 30px;
    text-align: center;
    color: white;
    cursor: pointer;
  }

  *:not(.removeFileButton){
    border-radius: 0; 
    margin: 0;
    height: 59px;
    width: auto;
  }

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    margin: 5px;

    span {
      width: 20px;
      height: 20px;
      font-size: 10px;
      line-height: 20px;
    }
  }


  // Styles for file type
  ${(props) =>
    props.fileType === FileType.Image &&
    `
    // Styles for image
  `}

  ${(props) =>
    props.fileType === FileType.Video &&
    `
    border: none;
    div div {
      border-radius: 50% !important; 
      font-size: 22px;
      width: 40px !important;
      height: 40px !important;
    }
  `}

  ${(props) =>
    props.fileType === FileType.Pdf &&
    `
    *:not(.removeFileButton){
      margin: 0;
      height: 60px !important;
      width: auto !important;
    }
  `}

  ${(props) =>
    props.fileType === FileType.Other &&
      `
      border: none;
    `
  }
`;
export const PostImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
  padding-left: 40px;
`;
export const ConfirmWindow = styled.div`
  display: ${(props) => props.display};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 900;
  color: #fff;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.7);

  .container {
    width: 40%;
    pointer-events: auto;
    background: #222;
    position: absolute;
    left: 30%;
    top: 30%;
    padding: 20px;
    min-width: 250px;
    z-index: 999;
  }

  .header {
    font-size: 2em;
    font-family: Helvetica, sans-serif;
    margin-bottom: 50px;
  }

  .footer {
    position: absolute;
    bottom: 20px;
    right: 30px;
    text-align: right;
  }
`;

export const AlertButton = styled.button`
  padding: 8px 11px 8px 11px;
  background: #222;
  color: #fff;
  border: 2px solid #222;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background: #555;
    border-color: #888;
  }
  &:active {
    border-color: #aaa;
  }
  &:focus {
    outline: 0;
  }

  &.btn-green {
    background: #49843b;
    border-color: #55a543;
  }
  &.btn-red {
    background: #913725;
    border-color: #a84835;
  }
`;

export const ButtonPlus = styled.label`
  border-radius: 50%;
  background: #41464d;
  line-height: 60px;
  text-align: center;
  width: 60px;
  height: 60px;
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  margin: 5px;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
`;

//menu
export const DotMenu = styled.div`
  .showLeft {
    padding: 5px;
  }

  img {
    width: 30px;
    border-radius: 50%;
    padding: 2px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  background: #222529;
  position: absolute;
  right: 0;
  top: 30px;

  .dropdown-content {
    position: relative;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .dropdown-content.show {
    display: block;
  }
`;

export const ActButton = styled.a`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 5px 0;
  cursor: pointer;
  color: white;
  &:hover {
    background: gray;
  }
  p {
    width: 50px;
  }
`;

export const IconsLi = styled.ul`
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  justify-content: space-around;
  padding: 0px 20px;
  height: 30px;
  align-items: center;
  cursor: pointer;
  li {
    background: none repeat scroll 0 0 white;
    height: 7px;
    width: 7px;
    line-height: 0;
    list-style: none outside none;
    margin-top: 3px;
    border-radius: 50%;
    pointer-events: none;
  }
`;
export const Dropdown = styled.div`
  width: 50px;
`;

export const Selected = styled.p`
  color: white;
  font-size: 24px;
  padding: 0px;
  border: 1px solid white;
  border-radius: 50%;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
`;

export const BackButton = styled.button`
  color: white;
  font-size: 24px;
  padding-right: 20px;
  background: none;
  border: none;
  cursor: pointer;
`;

export const ArrowButton = styled.button`
    display: inline-block;
    position: fixed;
    top: 50%;
    background-color: unset;
    border-radius: 60px;
    color: blue;
    border: none;
    font-size: 20px;
    cursor: pointer;
    height: 56px;
    z-index: 10000;
`;
