import React, {createContext, useState, useCallback, useContext, useEffect} from 'react';
import {LogSearchResultDto, MachineDto} from '../Models/LogSearchResultDto';
import { SlackSearchFiltersContext } from './SlackSearchFiltersContext';
import { SearchLogDto } from '../Models/SearchLogDto';
import axios from 'axios';
import { AOO_API_HOST } from '../../../env.js';
import { MachinesContext } from '../../../context/MachinesContext';

interface ISlackSearchResultsContext {
    searchedMachinesFiltered: LogSearchResultDto | null;
    fetchMachineLogs: (searchParams?: SearchLogDto, signal?: AbortSignal) => Promise<void>;
    findMachineById: (machineId: string) => MachineDto | null;
    isLoading: boolean;
    error: Error | null;
    selectedInspector: any | null;
    setSelectedInspector: React.Dispatch<React.SetStateAction<any | null>>;
}

export const SlackSearchResultsContext = createContext<ISlackSearchResultsContext | undefined>(undefined);

export const SearchResultsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [selectedInspector, setSelectedInspector] = useState(null);
    const [searchedMachines, setSearchedMachines] = useState<LogSearchResultDto | null>(null);
    const [searchedMachinesFiltered, setSearchedMachinesFiltered] = useState<LogSearchResultDto | null>(null); // filtered with out backend request

    const {composeSearchParameters} = useContext(SlackSearchFiltersContext);
    const {machineDtoMap} = useContext(MachinesContext);

    const fetchMachineLogs = useCallback(async (searchParams?: SearchLogDto, signal?: AbortSignal) => {
        setIsLoading(true);
        setError(null);

        try {
            // Use provided search parameters or compose from context
            const parameters = searchParams || composeSearchParameters();
            const response = await axios.post(
                `${AOO_API_HOST}/aoo-api/ProductionsLog/SearchLogs`,
                parameters,
                {
                    signal,
                }
            );
            setSearchedMachines(response.data || null);
        } catch (err) {
            console.error('Failed to fetch logs', err);
            setError(err instanceof Error ? err : new Error('Failed to fetch logs'));
        } finally {
            setIsLoading(false);
        }
    }, [composeSearchParameters]);

    const findMachineById = useCallback((machineId: string): MachineDto | null => {
        if (!searchedMachines) return null;

        // Helper function to search in a specific time period
        const searchInPeriod = (logs: { [key: string]: MachineDto[] }) => {
            for (const dateGroup of Object.values(logs)) {
                const machine = dateGroup.find(m => m.MachineId === machineId);
                if (machine) return machine;
            }
            return null;
        };

        // Search in all time periods
        return searchInPeriod(searchedMachines.YesterdayLogs) ||
            searchInPeriod(searchedMachines.LastWeekLogs) ||
            searchInPeriod(searchedMachines.LastMonthLogs) ||
            searchInPeriod(searchedMachines.PastLogs) ||
            null;
    }, [searchedMachines]);

    useEffect(() => {
        if (fetchMachineLogs)
            fetchMachineLogs();
    }, [fetchMachineLogs]);
    
    useEffect(() => {
        if (!searchedMachines) return;
    
        if (selectedInspector) {
            const filterMachines = (logs: { [key: string]: MachineDto[] }) => {
                return Object.fromEntries(
                    Object.entries(logs).map(([key, machines]) => [
                        key,
                        machines.filter(m =>machineDtoMap[m.MachineId]?.InspectorId === selectedInspector.WorkerId),
                    ])
                );
            };
    
            setSearchedMachinesFiltered({
                ...searchedMachines,
                YesterdayLogs: filterMachines(searchedMachines.YesterdayLogs),
                LastWeekLogs: filterMachines(searchedMachines.LastWeekLogs),
                LastMonthLogs: filterMachines(searchedMachines.LastMonthLogs),
                PastLogs: filterMachines(searchedMachines.PastLogs),
            });
        } else {
            setSearchedMachinesFiltered(searchedMachines);
        }
    }, [selectedInspector, searchedMachines, machineDtoMap]);

    return (
        <SlackSearchResultsContext.Provider
            value={{
                searchedMachinesFiltered,
                fetchMachineLogs,
                findMachineById,
                isLoading,
                error, 
                selectedInspector, 
                setSelectedInspector
            }}
        >
            {children}
        </SlackSearchResultsContext.Provider>
    );
};
