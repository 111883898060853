import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { NavLink, Content, ContentLog, Button } from "../NavbarStyles";
import { Title } from "./Styles";
import Machine from "./OpenLogMachine";
import { AOO_API_HOST } from "../../../env";
import axios from "axios";
import { GlobalContext } from "../../../context/GlobalContext";
import Routes from "../../Data/Routes";

export interface Machine {
  Id: String;
  MachineCode: String;
  MachineName: String;
  LastEntryDate: Date;
  isPinned: Boolean;
  IsUrgent: Boolean;
  EventState: String;
}

const Log = () => {
  let { state } = useLocation();
  const navigate = useNavigate();
  const { setMachineId, setLog, menuClicked, setMenuClicked, setisNavActive } =
    useContext(GlobalContext);

  const postPinnedMachine = async () => {
    let url =
      `/aoo-api/ProductionsLog/Pinnedlog/` +
      state.Id +
      "?isPinned=" +
      !state.isPinned;
    try {
      await axios.post(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    navigate(Routes.MainLog);
  };
  const pinMachine = () => {
    postPinnedMachine();
  };

  const openLog = () => {
    setMachineId(state.Id);
    setLog(true);
    setMenuClicked(false);
    setisNavActive(false);
  };
  return (
    <>
      <NavLink
        onClick={() => {
          navigate(Routes.MainLog);
        }}
        style={{ marginRight: 30 }}
      >
        Back to machines
      </NavLink>
      <Title>Machine Log Entry</Title>

      <Content>
        <ContentLog
          style={{
            height: 60 + "vh",
            paddingLeft: "30px",
            paddingRight: "30px",
            width: "100%",
          }}
        >
          <Title>
            [ {state.MachineCode}] {state.MachineName}
          </Title>
          <Button onClick={pinMachine}>
            {state.isPinned ? "Unpin" : "Pin"}
          </Button>
          <Button onClick={openLog}>Open log</Button>
        </ContentLog>
      </Content>
    </>
  );
};

export default Log;
