import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MachineList from "./MachineList";
import axios from "axios";
import {
  // Filter,
  Title,
  MachineFamily,
  Confirm,
  NavLink,
  MultiSelect,
  CancelMulti,
  Selected,
  CancelSelected,
  // State,
} from "../../NavbarStyles";
import _ from "lodash";
import Cancel from "../icons/Cancel";
import { AOO_API_HOST } from "../../../../env";
import SearchBar from "../../Components/SearchBar";
import Routes from "../../../Data/Routes";

function MachineOption() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [scrollHeight, setScroll] = useState(0);
  const [height, setHeight] = useState(window.innerHeight);
  const [isLoading, setLoading] = useState(false);
  const [groupId, setGroup] = useState(state.group);
  const [result, setResult] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [machine, setMachine] = useState([]);
  const [select, setSelect] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    if (height > 400) {
      setScroll(45);
    } else {
      setScroll(30);
    }
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  },[height]);

  const updateWidthAndHeight = () => {
    setHeight(window.innerHeight);
  };

  let url = AOO_API_HOST;
  if ((groupId === " ") | (groupId === undefined)) {
    url += "/aoo-api/Productions/all-machines";
  } else {
    url += "/aoo-api/Productions/group-machines?groupId=" + groupId;
  }
  useEffect(() => {
    if (groupId !== state.group) {
      setGroup(state.group);
      setLoading(false);
    }
    if (!isLoading) {
      axios.get(url).then((response) => {
        setResult(response.data);
        setSearchResults(response.data);
      });
      setLoading(true);
    }
  }, [state, isLoading,groupId,url]);

  let handleClick = (e, machine) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.currentTarget.classList.toggle("machine-active");
    selectSingle({
      id: machine.MachineId,
      machineName: machine.MachineName,
      duration: machine.EventDuration,
      worker: machine.WorkerName,
      code: machine.MachineCode,
      state: machine.EventState,
    });
  };
  const selectSingle = (item) => {
    let machineIds = Object.assign([], machine);

    if (_.findIndex(machineIds, { id: item.id }) === -1) {
      machineIds.push(item);
    } else {
      machineIds.splice(_.findIndex(machineIds, { id: item.id }), 1);
    }
    if (!select) {
      setSelect(false);
    }
    setMachine(machineIds);
  };
  const selectAll = () => {
    let machines = document.querySelectorAll(".machine");
    machines.forEach((element) => {
      element.classList.add("machine-active");
    });


    setMachine([]);
    let machineIds = [];
    result.forEach((machine) => {
      machineIds.push({
        id: machine.MachineId,
        machineName: machine.MachineName,
        duration: machine.EventDuration,
        worker: machine.WorkerName,
        code: machine.MachineCode,
        state: machine.EventState,
      });
    });
    setMachine(machineIds);
  };
  let confirmChoice = (e) => {
    if (machine.length === 0) {
      return;
    }
    let nextState = {
      machine: machine,
      group: groupId
    };
    navigate(Routes.MainSetFlagWorker, { state: nextState });
  };
  let CancelMultiSelect = () => {
    setMachine([]);
    let active = document.querySelectorAll(".machine-active");
    active.forEach((element) => {
      element.classList.remove("machine-active");
    });
    setVisible(false);
  };

  // const myStyle = {
  //   height: MobileView ? scrollHeight + "vh" : ""
  // };

  return (
    <MachineFamily>
      <MultiSelect>
        <NavLink
          style={{ borderBotton: "none" }}
          onClick={() => {
            navigate(Routes.MainSetFlagGroup);
          }}
        >
          Back to groups
        </NavLink>
        {(machine.length > 1 || visible === true) && (
          <CancelMulti onClick={(event) => CancelMultiSelect(event)}>
            <Selected>{machine.length} selected</Selected>
            <CancelSelected>
              <Cancel></Cancel>Cancel Multi-Select
            </CancelSelected>
          </CancelMulti>
        )}
      </MultiSelect>
      
      <Title>
        <SearchBar machines={result} setSearchResults={setSearchResults} onSearchChange={undefined}/>
      </Title>
      <Title>
        Select machine <Selected onClick={selectAll}>Select all</Selected>
      </Title>
      <MachineList
        handleClick={handleClick}
        height={scrollHeight}
        searchResults={searchResults}
      />

      <div style={{ width: "100%" }}>
        <Confirm onClick={(event) => confirmChoice(event)}>Ok</Confirm>
      </div>
    </MachineFamily>
  );
}

export default MachineOption;
