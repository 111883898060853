import React, { useCallback, useContext, useState, useEffect } from "react";
import { SlackViewMobileMachinesContainer } from "./SlackViewMobileMachinesContainer";
import { GlobalContext } from "../../../context/GlobalContext";
import { SlackViewUIContext } from "../Context/SlackViewUIContext";
import { SlackLocalStoreManager } from "../Utils/SlackLocalStoreManager";

export const SlackViewMobile = () => {
  const { isSearchOpen, setIsSearchOpen } = useContext(SlackViewUIContext);
  const { log } = useContext(GlobalContext);

  const localStoreManager = SlackLocalStoreManager.getInstance();

  localStoreManager.cleanExpired();
  const [readMachines, setReadMachines] = useState(
    localStoreManager.getMachines()
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setReadMachines(localStoreManager.getMachines());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [localStoreManager]);

  const setReadMachine = (id: string, value: boolean, postCount: number) => {
    if (value) {
      localStoreManager.addMachine(id, postCount);
    } else {
      localStoreManager.removeMachine(id);
    }

    setReadMachines(localStoreManager.getMachines());
  };

  const onSearchButtonClick = useCallback(() => {
    setIsSearchOpen(!isSearchOpen);
  }, [isSearchOpen, setIsSearchOpen]);

  return (
    <div
      style={{
        backgroundColor: "rgb(94, 54, 95)",
        height: "100vh",
        width: log ? "100%" : "initial",
        marginTop: "6vh",
      }}
    >
      <SlackViewMobileMachinesContainer
        onSearchButtonClick={onSearchButtonClick}
        readMachines={readMachines}
        setReadMachine={setReadMachine}
      />
    </div>
  );
};
