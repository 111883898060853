import { getMachineStatus } from "../WidgetActions";
import React, { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
interface Machine {
  Id: string;
  EventId: string;
  Name: string;
  EventDuration: number;
  EventsCount: number;
  MachineCode: string;
  Workers: WorkerDataItem[];
  MachineReason: string;
  IsUrgent: boolean | null;
  EventState: string;
  DaysToFix: number | null;
}
interface WorkerDataItem {
  FullName: string;
  Initials: string;
}
function Mark({
  machine,
  setEventOption,
  color,
}: {
  machine: Machine;
  setEventOption: any;
  color: String;
}) {
  const { setLog } = useContext(GlobalContext);

  if(color === "R" || color === "Y" || color === "B"){
    return (
      <li
        className={"menu-item"}
        onClick={() => {
          setLog(true);
          if(color === "B"){
            setEventOption({ color: "G", isUrgent: true });
          }
          else{
            getMachineStatus(machine.Id)
              .then((status) => {
                if (status) {
                  setEventOption({ color: status, isUrgent: true });
                } else {
                  setEventOption({ color: "G", isUrgent: true });
                }
              });
          }
        }}
      >
        {color === "B" ? "Turn Green" : "Mark as fixed"}
      </li>
    );
  }
  else{
    return <></>;
  }
}

export default Mark;
