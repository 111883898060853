import React, {useState, useContext, useEffect, useCallback, useRef, forwardRef} from "react";
import { Icons } from "../icons/Icons";
import BackgroundLetterAvatars from "./Avatar";
import Checkbox from "@mui/material/Checkbox";
import OpenFullImage from "./OpenFullImage";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import {
  PostContainer,
  Poster,
  PostItem,
  Time,
  Line,
  PostImageContainer,
  DotMenu, PostImageToolTip,
} from "../style/LogStyles";
import PostImageItem from "../../PostItem/components/PostImageItem";
import BasicMenu from "./BasicMenu";
import {initialPostData, LogContext} from "../../../../context/LogContext";
import ColorChange from "../components/ColorChange";
import {GlobalContext} from "../../../../context/GlobalContext";
import {PostImage} from "../log.typing";

const Post = forwardRef(({ post, scrollToPost }, ref) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [menu, setMenu] = useState(false);
  const {machineId} = useContext(GlobalContext);
  const {
    postImages,
    postData,
    setPostData,
    editMode,
    posts,
    setId,
    isLoading,
    fetchData,
    PAGE_SIZE,
    activePage,
    totalPages,
    selectedImageData,
    setReset,
    setSelectedImageData} = useContext(LogContext);
  const [ currentFullScreenImageIndex, setCurrentFullScreenImageIndex ] = useState(0);
  const [isFullImageOpen, setIsFullImageOpen] = useState(false);
  const [isFetchRequired, setIsFetchRequired] = useState(false);
  const [isFetchDone, setIsFetchDone] = useState(true);
  const [isLoadingPreviousImageRequested, setIsLoadingPreviousImageRequested] = useState(false);
  const [isLoadingNextImageRequested, setIsLoadingNextImageRequested] = useState(false);
  const handleButtonClick = () => {};

  const handleMouseEnter = (e) => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
    setMenu(false);
  };

  const checkPreviousElementExists = useCallback(() => {
    const previousIndex = currentFullScreenImageIndex - 1;
    const previousElementExistsInContext = postImages[previousIndex] !== undefined;
    if (previousElementExistsInContext) {
      return previousElementExistsInContext;
    }
    if (!previousElementExistsInContext && !isLoading && activePage < totalPages)
    {
      return null;
    }
    return previousElementExistsInContext;
  }, [isLoading, activePage, currentFullScreenImageIndex, postImages, totalPages]);

  const loadPreviousImage = useCallback(async () => {
    setIsLoadingPreviousImageRequested(true)
  }, [setIsLoadingPreviousImageRequested]);

  const checkNextElementExists = useCallback(() => {
    const nextImageIndex = currentFullScreenImageIndex + 1;
    return  postImages[nextImageIndex] !== undefined
  }, [postImages, currentFullScreenImageIndex]);
  
  const setPreviousImage = useCallback(() => {
      const newImageIndex = currentFullScreenImageIndex - 1;
      setSelectedImageData(postImages[newImageIndex]);
      setId(posts.find(post => post.Images.includes(postImages[newImageIndex])).PostId);
  }, [currentFullScreenImageIndex, postImages, posts, setId, setSelectedImageData])
  
  const setNextImage = useCallback(() => {
    const newImageIndex = currentFullScreenImageIndex + 1;
    setSelectedImageData(postImages[newImageIndex]);
    setId(posts.find(post => post.Images.includes(postImages[newImageIndex])).PostId);
  }, [currentFullScreenImageIndex, postImages, posts, setId, setSelectedImageData])

  const loadNextImage = () => {
    setIsLoadingNextImageRequested(true);
  }

  const openFullScreenImage = (image) => {
    setSelectedImageData(image);
    const post = getCurrentPost(image.Id);
    setId(post.PostId);
    setIsFullImageOpen(true);
  };

  const getCurrentPost = useCallback((postImageId) => {
    if (!postImageId)
    {
      console.error("Post Image Id was " + postImageId)
      return null
    }
    return posts.find(post =>
        post.Images && post.Images.find(postImage => postImage.Id === postImageId))
  }, [posts]);

  const getToolTipContent = (post, onCloseClick) => {
    if (!post)
    {
      return (<></>)
    }
    return (
        <PostImageToolTip>
          <button
              onClick={onCloseClick}
              style={{
                backgroundColor: 'transparent',
                position: 'absolute',
                left: '93%',
                top: 0,
                color: 'white',
                border: 'none',
                fontSize: '20px',
                cursor: 'pointer',
                textAlign: 'right',
                padding: '5px',
                verticalAlign: 'top',
              }}
          >
            X
          </button>
          <Poster>
            <BackgroundLetterAvatars fullName={post.Poster} initials={post.Initials}/>
          </Poster>
          <div style={{paddingRight: '5px', fontSize: '0.83rem', display: 'flex'}}>
            <h4 style={{color: 'white', paddingLeft: '5px'}}>{post.Poster}</h4>
            <Time>{post.Date}</Time>
            <Time>{post.Time}</Time>
          </div>
          <div>
            {post.PartReplacement && <PublishedWithChangesIcon style={{color: 'white'}}/>}
          </div>
          {post.MachineStatusEventChange && <ColorChange sideSize={16} colorChangeString={post.MachineStatusEventChange}/>}
        </PostImageToolTip>
    )
  }

  const getPoster = (post) => {
    return (
        <Poster>
          <BackgroundLetterAvatars fullName={post.Poster} initials={post.Initials}/>
          <p>{post.Poster}</p>
          <Time>{post.Time}</Time>
          {post.MachineStatusEventChange && <div style={{marginLeft: '12px'}}><ColorChange sideSize={24}
              colorChangeString={post.MachineStatusEventChange}></ColorChange></div>}
          {post.PartReplacement && <PublishedWithChangesIcon style={{color: 'white', marginLeft: "12px"}}/>}
        </Poster>
    )
  }

  const handleCloseFullScreenImage = () => {
    setIsFullImageOpen(false);
    setSelectedImageData(null);
    setPostData(initialPostData);
    setId(undefined);
    setReset(true);
  };

  useEffect(() => {
    if (selectedImageData && postImages && postImages.length > 0)
    {
      const newImageIndex = postImages.findIndex(postImage =>
          selectedImageData?.Id === postImage?.Id);

      if (newImageIndex > -1)
        setCurrentFullScreenImageIndex(newImageIndex);
    }
  }, [postImages, selectedImageData, setCurrentFullScreenImageIndex]);

  useEffect(() => {
    if (post.PostId === postData.PostId) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  }, [post.PostId, postData.PostId]);

  useEffect(() => {
    const loadPostsAsync = async () => {
      const pageNumber = activePage + 1;
      const requestParams = {
        MachineId: machineId,
        PageSize: PAGE_SIZE.current,
      }
      if (!isLoading && isFetchRequired && !isFetchDone && machineId && machineId.length > 0) {
          if (activePage < totalPages) {
            await fetchData("GetPaginatedLog", {
              ...requestParams,
              PageNumber: pageNumber,
            });
          }
      }
    }
    loadPostsAsync();
    return () => {setIsFetchDone(true)}
  },[PAGE_SIZE, isFetchDone, activePage, fetchData, isFetchRequired, isLoading, machineId, totalPages]);

  useEffect(() => {
    const result = checkPreviousElementExists();
    if (result === true && (isLoadingPreviousImageRequested || (isFetchDone && isFetchRequired)))
    {
      setPreviousImage();
      if (isFetchRequired) setIsFetchRequired(false);
      setIsLoadingPreviousImageRequested(false);
    }
    if (result === null && isLoadingPreviousImageRequested && !isFetchRequired)
    {
      setIsFetchRequired(true);
      setIsFetchDone(false);
      setIsLoadingPreviousImageRequested(false);
    }
    if (result === false)
    {
      setIsLoadingPreviousImageRequested(false);
    }
  }, [checkPreviousElementExists, isFetchDone, isFetchRequired, isLoadingPreviousImageRequested, setPreviousImage]);

  useEffect(() => {
    if (isLoadingNextImageRequested && checkNextElementExists())
    {
      setNextImage()
      setIsLoadingNextImageRequested(false);
    }
  }, [setNextImage, checkNextElementExists, setSelectedImageData, postImages, currentFullScreenImageIndex, isLoadingNextImageRequested]);

  return (
    <PostContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={isEditMode && editMode ? "editMode" : ""}
    >
      <PostItem>
        {getPoster(post)}

        <DotMenu>
          <div ref={ref}>
            <BasicMenu
              id={post.PostId}
              isMouseOver={isMouseOver}
              onButtonClick={handleButtonClick}
              menu={menu}
              setMenu={setMenu}
            />
          </div>
        </DotMenu>

        {post.Symptoms != null && (
          <Line>
            <div style={{ width: "30px" }}>
              <Icons
                value={"symptoms"}
                height={30}
                width={35}
                color={"#ffffff"}
              />
            </div>

            <p> {post.Symptoms}</p>
          </Line>
        )}
        {post.Action != null && (
          <Line>
            <div style={{ width: "30px" }}>
              {" "}
              <Icons
                value={"action"}
                height={20}
                width={25}
                color={"#ffffff"}
              />{" "}
            </div>
            <p> {post.Action}</p>
          </Line>
        )}
        {post.Question != null && (
          <Line>
            <Icons
              value={"question"}
              height={30}
              width={35}
              color={"#ffffff"}
            />
            <p> {post.Question}</p>
          </Line>
        )}
        {post.Plan != null && (
          <Line>
            <div style={{ width: "30px" }}>
              {" "}
              <Icons value={"plan"} height={20} width={25} color={"#ffffff"} />
            </div>
            <p> {post.Plan}</p>
          </Line>
        )}
        {post.Observation != null && (
          <Line>
            <Icons
              value={"observation"}
              height={30}
              width={35}
              color={"#ffffff"}
            />
            <p> {post.Observation}</p>
          </Line>
        )}
        {post.ImprovementProposition != null &&
          post.ImprovementProposition !== "" && (
            <Line>
              <div style={{ width: "30px" }}>
                {" "}
                <Icons
                  value={"improvement"}
                  height={30}
                  width={35}
                  color={"#ffffff"}
                />
              </div>
              <p> {post.ImprovementProposition}</p>
            </Line>
          )}
        {post.Hypothesis != null && (
          <Line>
            <div style={{ width: "30px" }}>
              {" "}
              <Icons
                value={"hypothesis"}
                height={30}
                width={35}
                color={"#ffffff"}
              />
            </div>
            <p> {post.Hypothesis}</p>
          </Line>
        )}

        {post.Comment != null && (
          <Line>
            <div style={{ width: "30px" }}>
              {" "}
              <Icons
                value={"comment"}
                height={20}
                width={25}
                color={"#ffffff"}
              />
            </div>
            <p> {post.Comment}</p>
          </Line>
        )}

        {isFullImageOpen && selectedImageData && (
          <OpenFullImage
            imageData={selectedImageData}
            onClose={handleCloseFullScreenImage}
            loadPreviousImage={loadPreviousImage}
            loadNextImage={loadNextImage}
            getAssociatedPost={getCurrentPost}
            getToolTipContent={getToolTipContent}
            scrollToPost={scrollToPost}
          />
        )}

        {post.Images != null && post.Images.length > 0 && (
          <PostImageContainer>
            {post.Images.map((item, index) => (
              <PostImageItem
                key={index}
                imageKey={index}
                postImage={item}
                onImageClick={openFullScreenImage}
              />
            ))}
          </PostImageContainer>
        )}
      </PostItem>
    </PostContainer>
  );
});

export default Post;
