import React, { useContext, useEffect } from "react";
import {Routes as Switch, Route, Navigate, useNavigate } from "react-router-dom";
import Main from "./Main";
import Resolved from "./InspectionFlags/ResolvedFlags";
import Groups from "./RemoteScan/Groups";
import Machine from "./RemoteScan/Machine";
import Reason from "./RemoteScan/Reason";
import Final from "./RemoteScan/FinalOption";
import Inspection from "./InspectionFlags/InspectionFlags";
import GroupOption from "./InspectionFlags/Options/GroupOption";
import MachineOption from "./InspectionFlags/Options/MachineOption";
import WorkerOption from "./InspectionFlags/Options/WorkerOption";
import Result from "./InspectionFlags/Options/Result";
import Log from "./Log/Log";
import BreakdownReport from "./BreakdownReport/MachinesBreakdownReport";
import DailyLossReport from "./DailyLossReport/MachinesDailyLossReport";
import Options from "./Log/Options";
import Menu from "./Burger";
import { Navs, NavItems } from "./NavbarStyles";
import { GlobalContext } from "../../context/GlobalContext";
import {RemoteScanSubmenu} from "./RemoteScan/RemoteScanSubmenu";
import {ScanQrCode} from "./RemoteScan/ScanQrCode";
import {isMobile} from "react-device-detect"
import Routes from "../Data/Routes";
import { ScanButton } from "./RemoteScan/ScanButton";
export interface NavbarMainProps {
    currentView: number,
    views: number,
    goToPreviousView: () => {},
    goToNextView: () => {}
}

export const NavbarMain: React.FC<NavbarMainProps> = ({currentView, views, goToPreviousView, goToNextView}) => {
    const navigate = useNavigate();
    const { isNavActive, setisNavActive, setMenuClicked, isSlackViewActive, setIsSlackViewActive } =
        useContext(GlobalContext);

    useEffect(() => {
        if (isSlackViewActive) {
            goToNextView()
        }
        else {
            goToPreviousView()
        }
    }, [goToNextView, goToPreviousView, isSlackViewActive]);
    const handleClickMenu = () => {
      if (isNavActive) {
        navigate(Routes.Main);
      }
  
      setisNavActive((current) => !current);
    };

    const onClickScanner = () => {
        navigate(Routes.MainRemoteScanQrCode);
        setisNavActive(true)
        setMenuClicked(true)
    }

    return (
        <>
            <header className={"navbar"}>
                <div className={`navbar-content ${isMobile ? "mobile" : "web"}`}>
                  <Navs>
                    <img
                      className="logoContainer top"
                      alt="logo"
                      src={require("../../assets/images/av-brand.png")}
                    />
                  </Navs>

                  <div className="navbar-right-section">
                    <img
                      className="pointer"
                      src={require("../../assets/icons/message.png")}
                      alt="message"
                      width="50px"
                      height="50px"
                      onClick={() => setIsSlackViewActive(true)}
                    />
                    {isMobile && (
                      <ScanButton
                        height="50px"
                        width="50px"
                        onClick={onClickScanner}
                      />
                    )}
                    <Menu handleClick={handleClickMenu} />
                  </div>
                </div>

                <NavItems className={isNavActive ? "nav-show" : ""}>
                    <Switch>
                        <Route path="/main" element={<Main/>}/>
                        <Route path="/main/resolved" element={<Resolved/>}/>
                        <Route path="/main/inspection" element={<Inspection/>}/>
                        <Route path="/main/setflag/group" element={<GroupOption/>}/>
                        <Route path="/main/setflag/machine" element={<MachineOption/>}/>
                        <Route path="/main/setflag/worker" element={<WorkerOption/>}/>
                        <Route path="/main/setflag/result" element={<Result/>}/>
                        <Route path="/main/remote" element={<RemoteScanSubmenu/>}/>
                        <Route path="/main/remote/group" element={<Groups/>}/>
                        <Route path="/main/remote/machine" element={<Machine/>}/>
                        <Route path="/main/remote/scanQrCode/:machineId?" element={<ScanQrCode/>}/>
                        <Route path="/main/remote/reason" element={<Reason onReasonClick={undefined}/>}/>
                        <Route path="/main/remote/final" element={<Final onFinalClick={undefined} machine={undefined}
                                                                         includeBackButton={true}/>}/>
                        <Route path="/main/log" element={<Log/>}/>
                        <Route path="/main/log/options" element={<Options/>}/>
                        <Route path="/main/breakdownReport" element={<BreakdownReport/>}/>
                        <Route path="/main/dailyLossReport" element={<DailyLossReport/>}/>
                        <Route path="*" element={<Navigate to="/main"/>}/>
                    </Switch>
                </NavItems>
            </header>
        </>
    );
};
