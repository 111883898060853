import React, { useCallback, useContext, useState } from "react";

import { QRScanner } from "../../Navbar/RemoteScan/QRScanner";
import { ScanButton } from "../../Navbar/RemoteScan/ScanButton";
import { GlobalContext } from "../../../context/GlobalContext";

interface SlackViewScanQrCodeProps {
  onMachineIdCaptured: (machineId: string) => void;
}

export const SlackViewScanQrCode: React.FC<SlackViewScanQrCodeProps> = ({
  onMachineIdCaptured,
}) => {
  const [isScanOpen, setIsScanOpen] = useState<boolean>(false);
  const { log, setLog, setMachineId } = useContext(GlobalContext);
  const onScanSuccess = useCallback(
    (result: string) => {
      setIsScanOpen(false);
      onMachineIdCaptured(result);
    },
    [onMachineIdCaptured]
  );

  const onQrCodeScannerIconClick = async () => {
    if (log) {
      setMachineId(null);
      setLog(false);
    }

    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
      });

      setIsScanOpen(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {isScanOpen && (
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              zIndex: 2,
              backgroundColor: "black",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
          >
            <QRScanner onScanSuccess={onScanSuccess} />
          </div>
        </div>
      )}

      <ScanButton
        onClick={onQrCodeScannerIconClick}
        background="#A665A8"
        padding="8px 12px"
        margin="0 0 0 15px"
      />
    </>
  );
};
