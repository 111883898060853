import React from 'react';

export interface SubMenuItem {
  id: string;
  text: string;
  icon?: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
}

interface SubMenuProps {
  items: SubMenuItem[];
  type: string;
  title: string;
  isActive: boolean;
  rootRef: (ref: HTMLUListElement | null) => void;
  style: React.CSSProperties;
  onToggle: (e: React.MouseEvent) => void;
  onTouchStart: (e: React.TouchEvent) => void;
  onTouchEnd: (e: React.TouchEvent) => void;
  onTouchCancel: () => void;
  onSubmenuTouch: (e: React.TouchEvent) => void;
  onSubmenuScroll: (e: React.WheelEvent) => void;
  onMouseEnter?: (e: React.MouseEvent) => void;
  onMouseLeave?: (e: React.MouseEvent) => void;
}

const SubMenu: React.FC<SubMenuProps> = ({
  items,
  type,
  title,
  isActive,
  rootRef,
  style,
  onToggle,
  onTouchStart,
  onTouchEnd,
  onTouchCancel,
  onSubmenuTouch,
  onSubmenuScroll,
  onMouseEnter,
  onMouseLeave
}) => {
  return (
    <li
      className={`menu-item ${type} ${isActive ? 'active' : ''}`}
      onClick={onToggle}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchCancel={onTouchCancel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {title}
      <ul
        className={`menu-sub-list ${type} ${isActive ? 'open-sub-menu' : ''}`}
        ref={rootRef}
        style={isActive ? style : {}}
        onTouchStart={onSubmenuTouch}
        onTouchMove={onSubmenuTouch}
        onTouchEnd={onSubmenuTouch}
        onWheel={onSubmenuScroll}
        onMouseLeave={(e) => {
          if (onMouseLeave && !(e.relatedTarget === e.currentTarget.parentElement)) {
            onMouseLeave(e);
          }
        }}
        onMouseEnter={(e) => {
          e.stopPropagation();
        }}
      >
        {items.map((item) => (
          <li
            className="menu-button"
            key={item.id}
            onClick={(e) => {
              e.stopPropagation();
              item.onClick(e);
            }}
          >
            {item.text}
            {item.icon}
          </li>
        ))}
      </ul>
    </li>
  );
};

export default SubMenu; 