import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { PostExpert } from "../log.typing";
import { AOO_API_HOST } from "../../../../env";
import { ActionButton, SimpleContainer } from "../style/LogStyles";
import { LogContext } from "../../../../context/LogContext";
import { Selected } from "../style/LogStyles";
import { GlobalContext } from "../../../../context/GlobalContext";
import BackgroundLetterAvatars from "./Avatar";
export interface SimpleDialogProps {
  fetched?: string;
  poster?: PostExpert;
  setPoster: React.Dispatch<React.SetStateAction<PostExpert>>;
  anchorEl?: HTMLElement | undefined;
  isReadOnly: boolean;
  mode: UserDialogMode | undefined;
}

export enum UserDialogMode {
  openedFromLogEntry = "openedFromLogEntry",
  machineSearchFilter = "machineSearchFilter",
}

export default function SimpleDialog(props: SimpleDialogProps) {
  const ITEM_HEIGHT = 48;
// Local state for machine search filter mode
  const [localAnchorEl, setLocalAnchorEl] = useState<HTMLElement | null>(null);
  const [localPoster, setLocalPoster] = useState<PostExpert | null>(props.poster || null);
  const [users, setUsers] = useState<PostExpert[]>([]);

  // Context for log entry mode
  const logContext = useContext(LogContext);
  const globalContext = useContext(GlobalContext);
  // Determine which state to use based on mode
  const isContextMode = props.mode === UserDialogMode.openedFromLogEntry;
  const currentAnchorEl = isContextMode ? logContext.anchorEl : localAnchorEl;
  const currentPoster = isContextMode ? logContext.poster : (props.poster || localPoster);
  const setCurrentAnchorEl = isContextMode ? logContext.setAnchorEl : setLocalAnchorEl;
  const setCurrentPoster = isContextMode ? logContext.setPoster :
      (props.setPoster || setLocalPoster);

  const isMenuOpen = Boolean(currentAnchorEl);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (props.mode === UserDialogMode.machineSearchFilter && currentPoster) {
      setCurrentPoster(null);
      return;
    }

    if (!props.isReadOnly) {
      setCurrentAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setCurrentAnchorEl(null);
  };

  const handleMenuItemClick = (user: PostExpert) => {
    setCurrentPoster(user);
    setCurrentAnchorEl(null);
  };

  useEffect(() => {
    if ((logContext.editMode || props.isReadOnly) && props.fetched) {
      const findObjectById = (id) => {
        return users.find((obj) => obj.Id === id);
      };

      const foundObject = findObjectById(props.fetched);
      setCurrentPoster(foundObject);
    }
  }, [logContext.editMode, props.fetched]);

  useEffect(() => {
    if (props.mode === UserDialogMode.machineSearchFilter) {
      setUsers(globalContext.workers);
      return;
    }

    const fetchData = async (url) => {
      try {
        const response = await axios.get(
          AOO_API_HOST + "/aoo-api/ProductionsLog/" + url
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData("GetWorkers");
  }, []);

  return (
    <SimpleContainer>
      {props.mode === UserDialogMode.machineSearchFilter ? (
        <div
          className={`inspection-user-opener pointer ${props.poster ? "selected" : ""}`}
          onClick={handleMenuOpen}
        >
          <img
            src={require(props.poster
              ? "../../../../assets/icons/flag-filled-yellow.png"
              : "../../../../assets/icons/flag-white-empty.png")}
            alt="flag"
            width={32}
            height={32}
          />
          {props.poster && (
            <BackgroundLetterAvatars
              fullName={props.poster.FullName}
              initials={props.poster.Initials}
            />
          )}
        </div>
      ) : (
        <ActionButton
          type="button"
          aria-controls={isMenuOpen ? "long-menu" : undefined}
          aria-expanded={isMenuOpen ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleMenuOpen}
        >
          {!props.poster && (
            <PersonAddIcon style={{ color: "white", fontSize: "35px" }} />
          )}

          {props.poster && (
            <div>
              <Selected style={{ padding: "0", margin: "0 10px 0 5px" }}>
                {props.poster?.Initials}
              </Selected>
            </div>
          )}
        </ActionButton>
      )}
      <Menu
          id="long-menu"
          MenuListProps={{ "aria-labelledby": "long-button" }}
          anchorEl={currentAnchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          slotProps={{
            paper: {
              className: "scrollbar-hidden",
              style: {
                backgroundColor: "#222529",
                color: "white",
                width: "20ch",
              },
            },
          }}
      >
        {users.map((option: any) => (
            <MenuItem
                key={props.mode + option.Id ?? option.WorkerId}
                onClick={() => handleMenuItemClick(option)}
            >
              {option.FullName}
            </MenuItem>
        ))}
      </Menu>

    </SimpleContainer>
  );
}
