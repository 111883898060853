export enum MachineColors {
  Red = "#DD8CAE",
  LightRed = "#F0CCDB",
  Yellow = "#F2D846",
  LightYellow = "#F0ECAE",
  Blue = "#599EFF",
  LightBlue = "#9DBEF4",
  Green = "#D4F0C2",
}

export enum StatusColors {
  Red = "R",
  Yellow = "Y",
  Blue = "B",
  Green = "G",
}

export const ColorMap = {
  [StatusColors.Red]: {
    urgent: MachineColors.Red,
    nonUrgent: MachineColors.LightRed,
  },
  [StatusColors.Yellow]: {
    urgent: MachineColors.Yellow,
    nonUrgent: MachineColors.LightYellow,
  },
  [StatusColors.Blue]: {
    urgent: MachineColors.Blue,
    nonUrgent: MachineColors.LightBlue,
  },
  [StatusColors.Green]: {
    urgent: MachineColors.Green,
    nonUrgent: MachineColors.Green,
  },
};
