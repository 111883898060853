import React, { useCallback, useEffect, useState } from "react";
import { useZxing } from "react-zxing";
import { useMediaDevices } from "react-media-devices";
import { ColorRing } from "react-loader-spinner";
import { Select, SelectChangeEvent } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { QrCodeScannerComponentWrapper } from "../NavbarStyles";

interface QRScannerProps {
    onScanSuccess: (result: string) => void;
    isEnabled?: boolean;
}

export const QRScanner: React.FC<QRScannerProps> = ({
    onScanSuccess,
    isEnabled = true,
  }) => {
    const constraints: MediaStreamConstraints = {
        video: true,
        audio: false,
    };
    const machineIdPrefix = "MACHINE-"

    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isPermissionGranted, setIsPermissionGranted] = useState(false);
    const [isScannerReady, setIsScannerReady] = useState(false);
    const [currentCameraDevice, setCurrentCameraDevice] = useState<MediaDeviceInfo | undefined>(undefined);

    const { devices, loading } = useMediaDevices({ constraints });

    const { ref } = useZxing({
        paused: !currentCameraDevice?.deviceId || !isEnabled,
        deviceId: currentCameraDevice?.deviceId,
        onDecodeResult(result) {
            const resultText = result.getText().replace(machineIdPrefix, "");
            onScanSuccess(resultText);
            setCurrentCameraDevice(undefined);
        },
    });

    const getLoadingSpinner = useCallback(() => {
        return (
            <ColorRing
                visible={loading && !currentCameraDevice}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
            />
        );
    }, [currentCameraDevice, loading]);

    const getAvailableCameraDevices = useCallback(() => {
        return devices && devices
            ?.filter(device => device.kind === "videoinput" && !device.label.toLowerCase().includes('front'))
            ?.map((device, i) => (
                <MenuItem key={i} value={device.deviceId}>{device.label}</MenuItem>
            ));
    }, [devices]);

    const handleCameraDeviceChange = useCallback((e: SelectChangeEvent) => {
        const selectedDevice = devices?.find(device => device.deviceId === e.target.value);
        if (selectedDevice !== undefined) {
            setCurrentCameraDevice(selectedDevice);
        } else {
            setIsError(true);
            setErrorMessage("No camera devices is currently available. Ensure camera is connected.");
        }
    }, [devices]);

    const checkCameraPermission = useCallback(async () => {
        const permissionName = "camera" as PermissionName;
        try {
            const result = await navigator.permissions.query({ name: permissionName });
            if (result.state === "granted" && !isPermissionGranted) {
                setIsPermissionGranted(true);
                setIsError(false);
                setErrorMessage("");
                return true;
            } else {
                setIsError(true);
                setErrorMessage("Camera permission is not granted. Ensure permission is allowed both for website and for browser.");
                return false;
            }
        } catch (exception) {
            setIsError(true);
            setErrorMessage(exception.toString());
        }
    }, [isPermissionGranted]);

    useEffect(() => {
        const permissionName = "camera" as PermissionName;
        try {
            navigator.permissions.query({ name: permissionName })
                .then(permission => {
                    permission.onchange = () => {
                        const state = permission.state === "granted";
                        if (state !== isPermissionGranted)
                            setIsPermissionGranted(state);
                        setIsError(!state);
                    };
                });
        } catch (exception) {
            setIsError(true);
            setErrorMessage(exception.toString());
        }
    }, [isPermissionGranted]);

    useEffect(() => {
        if (!isPermissionGranted) {
            checkCameraPermission().then(r => {
                if (r)
                    console.debug("Camera permission granted");
            });
        }
    }, [isPermissionGranted, checkCameraPermission]);

    useEffect(() => {
        if (devices && !loading && isPermissionGranted && !isScannerReady && isEnabled) {
            const backCameraTags = [
              "back",
              "rear",
              "environment",
              "задн",
              "trasera",
              "arrière",
            ];
            const availableCameras =
              devices.filter(
                (device) =>
                  device.kind === "videoinput" &&
                  !device.label.toLowerCase().includes("front")
              ) ?? [];
            const backSpecifiedCameras =
              availableCameras.filter((device) =>
                backCameraTags.some((tag) =>
                  device.label.toLowerCase().includes(tag)
                )
              ) ?? [];
            const preselectedCamera = backSpecifiedCameras.length
              ? backSpecifiedCameras[0]
              : availableCameras.length
              ? availableCameras[0]
              : null;
      
            if (preselectedCamera) {
              setCurrentCameraDevice(preselectedCamera);
              setIsScannerReady(true);
            }
          }
    }, [devices, loading, isPermissionGranted, isEnabled, isScannerReady]);

    return (
        <QrCodeScannerComponentWrapper>
            {isError ? (
                <p style={{ color: 'red' }}>{errorMessage}</p>
            ) : (
                <div style={{ paddingTop: "15px", display: 'inline' }}>
                    {getLoadingSpinner()}
                    {/*<p>Scan QR Code to open associated log page</p>*/}
                    <label htmlFor="camera-device-choose-select">Choose camera for scanning</label>
                    <Select
                        id="camera-device-choose-select"
                        value={currentCameraDevice?.deviceId ?? ''}
                        onChange={handleCameraDeviceChange}
                        style={{ color: 'white', maxWidth: '300px' }}
                        variant="outlined"
                    >
                        {getAvailableCameraDevices()}
                    </Select>
                    <video ref={ref} />
                </div>
            )}
        </QrCodeScannerComponentWrapper>
    );
};
