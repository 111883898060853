import React from 'react';

interface SlackViewColorSelectorProps {

}

export const SlackViewColorSelector : React.FC<SlackViewColorSelectorProps> = () => {

    return (
        <></>
    )
}
