import { Title, Content } from "./InspectionFlags_styles";
import { Widgets } from "./ActiveFlags";
import React, { useState } from "react";
import useSWR from "swr";

import { AOO_API_HOST } from "../../../env";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { NavLink } from "../NavbarStyles";
import Routes from "../../Data/Routes";

function Inspection() {
  let navigate = useNavigate();

  const [data, setData] = useState([]);

  const fetcher = (url) =>
    axios
      .request({
        url: url,
        method: "get",
        baseURL: AOO_API_HOST,
        responseType: "json",
      })
      .then((res) => setData(res.data));

  useSWR("/aoo-api/Productions/review-machine-inspectors", fetcher, {
    refreshInterval: 1000,
  }); 
  return (
    <div className="mainContent_InspectionFlags">
      <NavLink key={"link-main"}
        onClick={() => {
          navigate(Routes.Main);
        }}
        style={{ marginRight: 30 }}
      >
        Back to menu
      </NavLink>
      <NavLink 
        onClick={() => {
          navigate(Routes.MainSetFlagGroup);
        }}
        style={{ marginRight: 30 }}
      >
        Add new inspection flag
      </NavLink>
      <Title>Review machine inspectors</Title>
      <Content>
        {data.map((e, i) => {
          return <Widgets key={e.Id} data={e} />;
        })}
      </Content>
    </div>
  );
}

export default Inspection;
