import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Active from "../icons/Active";
import {
  WorkerFamily,
  WorkerW,
  Title,
  Confirm,
  WorkerFullName,
  FlagCount,
  BottomInfo,
  Phone,
  NavLink,
  MultiSelect,
  Content,
} from "../../NavbarStyles";
import { AOO_API_HOST } from "../../../../env";
import Inspection from "../Components/Inspection";
import { BrowserView, MobileView } from "react-device-detect";
import Routes from "../../../Data/Routes";

function WorkerOption() {
  let navigate = useNavigate();
  const { state } = useLocation();
  let [result, setResult] = useState([]);
  let [worker, setWork] = useState({});
  // let [group, setGroup] = useState(state.group);
  // let [machine, setMachine] = useState(state.machine);
  const [scrollHeight, setScroll] = useState(0);
  const [height, setHeight] = useState(window.innerHeight);
  const [isChecked, setIsChecked] = useState(false);
  let url = AOO_API_HOST + "/aoo-api/Productions/get-workers";
  function fetchMachines() {
    axios.get(url).then((response) => setResult(response.data));
  }

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    if (height > 900) {
      setScroll(60);
    } else if (height > 900) {
      setScroll(45);
    } else {
      setScroll(35);
    }
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, [height]);

  const updateWidthAndHeight = () => {
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    fetchMachines();
  });

  let handleClick = (event, key) => {
    if (document.querySelector(".machine-active")) {
      document
        .querySelector(".machine-active")
        .classList.remove("machine-active");
    }
    event.currentTarget.classList.toggle("machine-active");
    setWork(key);
  };

  let confirmChoice = (e) => {
    if (Object.keys(worker).length === 0) {
      return;
    }

    var result = {
      worker: worker,
      machine: state.machine,
      type: isChecked ? "I" : "W",
      group: state.group,
    };

    navigate(Routes.MainSetFlagResult, { state: result });
  };
  var prevstat = {
    group: state.group,
    machines: state.machine,
  };

  const myStyle = {
    height: MobileView ? scrollHeight + "vh" : "",
    // Add other styles as needed
  };

  return (
    <WorkerFamily>
      <MultiSelect>
        <NavLink
          onClick={() => {
            navigate(Routes.MainSetFlagMachine, { state: prevstat });
          }}
        >
          Back to machines
        </NavLink>
      </MultiSelect>
      <Inspection isChecked={isChecked} setIsChecked={setIsChecked} />
      <Title>Choose worker </Title>
      <Content style={{ height: scrollHeight + "vh" }}>
        {result.map((worker, key) => (
          <WorkerW
            onClick={(event) => handleClick(event, worker)}
            key={worker.WorkerId}
          >
            <Active />
            <WorkerFullName>
              {worker.FullName} <FlagCount>{worker.FlagsCount}</FlagCount>
            </WorkerFullName>
            <BottomInfo>
              <Phone> {worker.PhoneNumber}</Phone>
            </BottomInfo>
          </WorkerW>
        ))}
      </Content>
      <div style={{ display: "contents" }}>
        <Confirm onClick={(event) => confirmChoice(event)}>Ok</Confirm>
      </div>
    </WorkerFamily>
  );
}

export default WorkerOption;
