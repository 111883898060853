import React, {useState, useEffect, useContext, useCallback, useMemo} from "react";
import {NavbarMain} from "./NavbarMain";
import {NavbarSlackView} from "../NavbarSlackView";

function Nav({currentView, goToPreviousView, goToNextView, views}) {
  const currentNavMenu = useMemo(() => {
    if (currentView === 0) {
      return (<NavbarMain currentView={currentView} goToPreviousView={goToPreviousView}  goToNextView={goToNextView} views={views}/>)
    }
    if (currentView === 1)
    {
      return (<NavbarSlackView currentView={currentView} goToPreviousView={goToPreviousView} goToNextView={goToNextView} views={views}/>)
    }
    
  }, [currentView, goToNextView, goToPreviousView, views])

  return (
    <>
      {currentNavMenu}
    </>
  );
}

export default Nav;
