import React, {
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import ActiveStatusDialog from "./ActiveStatusDialog";
import FormComponent from "./Form";
import { SelectUserDialog } from "./SelectUserDialog";
import {PaginatedPost, PaginatedPostsParams} from "../log.typing";
import { AOO_API_HOST } from "../../../../env";
import { useSignalRConnection } from "./useSignalRConnection";
import Drawer from "./SwipeableDrawer";
import { fetchMachineData } from "../api/actions";
import { LogMachineData } from "../log.typing";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Overlay,
  Dialog,
  Header,
  CloseButton,
  ScrollContainer,
  Cover,
  BackButton,
} from "../style/LogStyles";
import { GlobalContext } from "../../../../context/GlobalContext";
import { LogContext, initialPostData } from "../../../../context/LogContext";
import ConfirmDelete from "./ConfirmDelete";
import Grouped from "./Grouped";
import { isBrowser, isMobile } from "react-device-detect";
import {HubConnectionState} from "@microsoft/signalr";
import {CircularProgress} from "@mui/material";
import {SlackSearchResultsContext} from "../../../Slack/Context/SlackSearchResultsContext";

const Log = () => {
  const { setMachineId, setMenuClicked, setisNavActive, backtoMenu, setBacktoMenu } =
    useContext(GlobalContext);
  const { log, setLog, eventOption, machineId } = useContext(GlobalContext);
  const {
    id,
    setReset,
    setPostData,
    setPosts,
    posts,
    activePage,
    setActivePage,
    totalPages,
    fetchData,
    PAGE_SIZE,
    isLoading,
    selectedImageData,
    getImageUrl
  } = useContext(LogContext);

  const slackSearchResultsContext = useContext(SlackSearchResultsContext); //be careful about this one, dirty hack
  const [machine, setMachine] = useState<LogMachineData>();
  const [event, setEvent] = useState<boolean>(false);
  const [statusOpen, setStatusOpen] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [img, setImg] = useState("");
  const [groupedData, setGroupedData] = useState({});
  const postsRef = useRef(null);
  const connection = useSignalRConnection(AOO_API_HOST + "/api/log", machineId);
  const collectRequestParams = useCallback(() => {
    return {
      MachineId: machineId,
      PageNumber: activePage,
      PageSize: PAGE_SIZE.current,
    };
  }, [PAGE_SIZE, machineId, activePage]);

  const getPosts = useCallback(
    (params: PaginatedPostsParams) => {
      if (
        params?.MachineId?.length > 0 && !isLoading
      ) {
        fetchData("GetPaginatedLog", params).catch((error: Error) => {
          console.error("Error while fetching posts:", error);
        });
      }
    },
    [fetchData, isLoading]
  );

  const connectToHub = useCallback(
      async (id: string) => {
        try {
          if (id) {
            await connection.start();
            await connection.invoke("Join", id);
          }
        } catch (e) {
          console.error(e);
        }
      },
      [connection]
  );

  const closeConnection = useCallback(async () => {
    try {
      if (connection)
      {
        await connection.stop();
        connection.off(); // Remove any remaining event handlers
      }
    } catch (e) {
      console.error(e);
    }
  }, [connection]);

  const loadMorePosts = useCallback(async () => {
      const pageNumber = activePage + 1;
      if (!isLoading && machineId && machineId.length > 0) {
        if (activePage < totalPages) {
          getPosts({
            ...collectRequestParams(),
            PageNumber: pageNumber == null ? activePage : pageNumber,
          });
          return true;
        }
        return false;
      }
  }, [activePage, collectRequestParams, getPosts, isLoading, machineId, totalPages]);

  const getMap = useCallback(() => {
    if (!postsRef.current) {
      // Initialize the Map on first usage.
      postsRef.current = new Map();
    }
    return postsRef.current;
  }, []);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        if (machineId) {
          // Check if machineId exists
          const userData = await fetchMachineData(machineId);
          setMachine(userData);
        }
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };
     // reset page after selected machine change
    fetchDataAsync();
    return () => {
      setActivePage(1);
      setIsInit(false);
      setPosts([]);
    }
  }, [machineId, setActivePage, setPosts]);

  useEffect(() => {
    if (
      machine !== null &&
      machine?.Cover !== undefined &&
      machine?.Cover !== null
    ) {
      if(machine.Cover.ImageData){
        setImg(`data:${machine.Cover.Content};base64,${machine.Cover.ImageData}`);
      }
      else
      {
        setImg(getImageUrl(machine.Cover.ContentId))
      }
    } else {
      setImg(null);
    }
  }, [machine]);

  useEffect(() => {

    if (connection && connection.state === HubConnectionState.Disconnected) {
      connectToHub(machineId);
    }
  }, [connection, connectToHub, machineId]);

  useEffect(() => {
    if (connection) {
      // Remove all event handlers
      connection.off();

      connection.on("ReceiveMessage", (jsonData) => {
        console.debug(jsonData);
      });

      connection.on("ItemCreated", (jsonData) => {
        const dataObject: PaginatedPost = JSON.parse(jsonData);
        setPosts((prevItems) => {
          const updatedArray = [...prevItems, dataObject];
          return updatedArray;
        });
      });

      connection.on("ItemRemoved", (deletedItemId) => {
        const dataObject = JSON.parse(deletedItemId);
        setPosts((prevItems) =>
          prevItems.filter((item) => item.PostId !== dataObject)
        );
      });

      connection.on("ItemUpdated", (jsonData) => {
        const dataObject: PaginatedPost = JSON.parse(jsonData);

        setPosts((prevData) => {
          const updatedData: PaginatedPost[] = prevData.map((item) => {
            if (item.PostId === dataObject.PostId) {
              item.Action = dataObject.Action;
              item.Observation = dataObject.Observation;
              item.Symptoms = dataObject.Symptoms;
              item.Hypothesis = dataObject.Hypothesis;
              item.Plan = dataObject.Plan;
              item.ImprovementProposition = dataObject.ImprovementProposition;
              item.Question = dataObject.Question;
              item.Comment = dataObject.Comment;
              item.Images = dataObject.Images;

              return {
                ...item,
                ...dataObject,
              };
            }
            return item;
          });

          return updatedData;
        });
      });
    }
  }, [connection, setPosts]);

  useEffect(() => {
    if (posts !== undefined) {
      const data = posts.reduce((acc, item) => {
        const date = item?.Date ?? "";
        return {
          ...acc,
          [date]: acc[date] ? [...acc[date], item] : [item],
        };
      }, []);
      setGroupedData(data);
    }
  }, [posts]);

  useEffect(() => {
    //setPosts([]);
    const collectedParams = collectRequestParams();
    if (!isInit && collectedParams.MachineId && collectedParams.MachineId.length > 0)
    {
      const params = {
        ...collectedParams,
        PageNumber: activePage,
      };
      getPosts(params);
      setIsInit(true);
    }

  }, [collectRequestParams, setIsInit, activePage, getPosts, isInit]);

  const Loader = useCallback(() => {
    return isLoading && <p style={{marginTop: '5px', width: '100%', textAlign: 'center'}}><CircularProgress/></p>
  }, [isLoading]);


  const backToMachines = () => {
    setMachineId(null);
    setLog(false);
    setMenuClicked(true);
    setisNavActive(true);
    setBacktoMenu(false);
    setIsInit(false);
    setPosts([]);
    setActivePage(1);
    if (slackSearchResultsContext)
      slackSearchResultsContext.fetchMachineLogs();
  };
  return (

    <Overlay position={slackSearchResultsContext && !isMobile ? 'initial' : 'fixed'} display={log ? "flex" : "none"}>
      <SelectUserDialog />
      <ConfirmDelete postId={id} />
      <ActiveStatusDialog
        option={eventOption}
        statusOpen={statusOpen}
        setEvent={setEvent}
        setLog={setLog}
        setStatusOpen={setStatusOpen}
        setPostData={setPostData}
        setPosts={setPosts}
        setReset={setReset}
        closeConnection={closeConnection}
        setMachineId={setMachineId}
        initialPostData={initialPostData}
      />
      <Dialog
        style={
          machine?.Cover !== null
            ? { minHeight: "150px" }
            : { minHeight: "0px" }
        }
      >
        {!slackSearchResultsContext && (
            <CloseButton
                onClick={() => {
                  if (event) {
                    setStatusOpen(true);
                  } else {
                    setPostData(initialPostData);
                    setPosts([]);
                    setReset(true);
                    closeConnection();
                    setMachineId("");
                    setLog(false);
                  }
                }}
            >
              &#10006;
            </CloseButton>
        )}
        <Cover
          style={
            machine?.Cover !== null && machine?.Cover !== undefined
              ? { backgroundImage: `url(${img})`, minHeight: "150px" }
              : {}
          }
        >
          <Header>
            {backtoMenu && (
                <ArrowBackIosIcon  onClick={backToMachines} style={{
                  height: '100%',
                  fontSize: '2rem',
                  cursor: 'pointer'
                }} />
            )}
            <div style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              <span style={{wordWrap: 'break-word'}}> {`[${machine?.MachineCode != null ? machine?.MachineCode : " "}]`}
                {machine?.MachineName}</span>
              {backtoMenu && (
                  <BackButton onClick={backToMachines}>
                    <p style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "11px",
                      paddingTop: '10px'
                    }}>
                      Back to machines
                    </p>
                  </BackButton>
              )}
            </div>
            <Loader/>
          </Header>{" "}
        </Cover>
        <ScrollContainer
            id={"infinite-scroll-container"}
            style={{
            display: "flex",
            flexDirection: "column-reverse",
            height: "100%",
            marginBottom: isMobile ? "60px" : "0",
          }}
        >
          <InfiniteScroll
            inverse={true}
            scrollableTarget={"infinite-scroll-container"}
            dataLength={posts.length}
            next={loadMorePosts}
            hasMore={!isLoading && activePage < totalPages}
            loader={<></>}
            style={{
              overflow: selectedImageData ? 'hidden' : 'visible'
            }}
          >
            {Object.entries(groupedData).map(([date, posts]) => (
                <Grouped ref={postsRef} getMap={getMap} key={date} date={date} posts={posts}/>
            ))}
          </InfiniteScroll>
        </ScrollContainer>
        {!isBrowser && log && !selectedImageData && (
          <Drawer scrollToPost={undefined} key={id + 'log'} isReadOnly={false} id={id} event={event} setEvent={setEvent} />
        )}
        {isBrowser && (
          <FormComponent isReadOnly={false} id={id} event={event} setEvent={setEvent} />
        )}
      </Dialog>
    </Overlay>
  );
};

export default Log;
