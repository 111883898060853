import React, {useContext, useRef} from "react";
import { isMobile } from "react-device-detect";

import { ReactComponent as OpenEnvelope } from "../../../assets/icons/open-envelope.svg";
import { MachineDto } from "../Models/LogSearchResultDto";
import { MachinesGroupType } from "../Models/MachinesGroupType";
import { useMachinesContext } from "../../../context/MachinesContext";
import { SlackSearchResultsContext } from "../Context/SlackSearchResultsContext";
interface SlackViewMobileMachineCardProps {
  machine: MachineDto;
  groupType: MachinesGroupType;
  isRead: boolean;
  onMachineLeftClick: (machineId: string) => void;
  onMachineRightClick: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    machineId: string
  ) => void;
  setReadMachine: (id: string, value: boolean, postCount: number) => void;
}

export const SlackViewMobileMachineCard: React.FC<
  SlackViewMobileMachineCardProps
> = ({
  machine,
  groupType,
  isRead,
  setReadMachine,
  onMachineRightClick,
  onMachineLeftClick,
}) => {
  const { machineColorMap, machineDtoMap } = useMachinesContext();
  const { selectedInspector } = useContext(SlackSearchResultsContext);
  const pressTimer = useRef(null);

  const handleTouchStart = (e) => {
    e.preventDefault(); // Prevents unwanted behavior like text selection
    pressTimer.current = setTimeout(() => {
      onMachineRightClick(e, machine.MachineId);
    }, 500); // Adjust the delay as needed
  };

  const handleTouchEnd = () => {
    clearTimeout(pressTimer.current); // Clear timeout if the user releases early
  };

  const showMessageCounter = groupType !== MachinesGroupType.PastEntries;
  const showEnvelope = groupType === MachinesGroupType.YesterdayLogs;

  const fullMachineName = `[${machine.MachineCode}] - ${machine.MachineName}`;

  const onEnvelopeClick: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();

    setReadMachine(machine.MachineId, false, machine.PostCount);
  };

  return (
    <div
      className={`slack-card ${isMobile ? "mobile" : "web"}`}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onContextMenu={(e) => onMachineRightClick(e, machine.MachineId)}
      onClick={() => onMachineLeftClick(machine.MachineId)}
    >
      <div className="group-name">{machine.GroupCode}</div>

      <span
        className="status-circle"
        style={{
          backgroundColor: machineColorMap[machine.MachineId] ?? "transparent",
        }}
      />

      <div className="machine-name" title={fullMachineName}>
        {fullMachineName}
      </div>
      <div className="right-group">
        {machineDtoMap[machine.MachineId]?.InspectorId && selectedInspector && (
          <img
            src={require("../../../assets/icons/flag-filled-yellow.png")}
            alt="flag"
            width={25}
            height={25}
          />
        )}

        {showMessageCounter ? (
          showEnvelope && isRead ? (
            <OpenEnvelope className="envelope" onClick={onEnvelopeClick} />
          ) : (
            <div className="counter">{machine.PostCount}</div>
          )
        ) : (
          <div className="spacer"></div>
        )}
      </div>
    </div>
  );
};
