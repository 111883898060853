import React, { createContext, useCallback, useState } from 'react';
import { SearchLogDto } from '../Models/SearchLogDto';

interface ISlackSearchFiltersContext {
    dateFilter: string[];
    setDateFilter: React.Dispatch<React.SetStateAction<string[]>>;
    searchInput: string;
    setSearchInput: React.Dispatch<React.SetStateAction<string>>;
    groups: string[];
    setGroups: React.Dispatch<React.SetStateAction<string[]>>;
    composeSearchParameters: () => SearchLogDto;
}

export const SlackSearchFiltersContext = createContext<ISlackSearchFiltersContext | undefined>(undefined);

export const SearchFiltersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [dateFilter, setDateFilter] = useState<string[]>([]);
    const [searchInput, setSearchInput] = useState('');
    const [groups, setGroups] = useState<string[]>([]);
    // Force a date to 00:00:00.000 local time
    const setToStartOfDay = (d: Date): Date => {
        // Create a new date to avoid modifying the input
        const newDate = new Date(d);
        // Get local timezone offset in minutes
        const tzOffset = newDate.getTimezoneOffset();
        // Set to start of day in local timezone
        newDate.setHours(0, 0, 0, 0);
        // Adjust for timezone offset to get correct UTC time
        newDate.setMinutes(newDate.getMinutes() - tzOffset);
        return newDate;
    };

// Return "YYYY-MM-DD" in local timezone
    const toDateOnly = (d: Date): string => {
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const getYesterdayFilter = (currentDate: Date) => {
        const end = setToStartOfDay(currentDate);
        const start = new Date(end);
        start.setDate(start.getDate() - 1);

        return {
            StartDateUTC: toDateOnly(start),
            EndDateUTC: toDateOnly(end),
        };
    };

    const getLastWeekFilter = (currentDate: Date) => {
        const yStart = new Date(currentDate);
        yStart.setDate(yStart.getDate() - 1);
        setToStartOfDay(yStart);

        const end = new Date(yStart);
        end.setMilliseconds(end.getMilliseconds() - 1);

        const start = new Date(end);
        start.setDate(start.getDate() - 6);
        setToStartOfDay(start);

        return {
            StartDateUTC: toDateOnly(start),
            EndDateUTC: toDateOnly(end),
        };
    };

    const getLastMonthFilter = (currentDate: Date) => {
        const { StartDateUTC: lwStartStr } = getLastWeekFilter(currentDate);
        const lwStart = new Date(lwStartStr); // "YYYY-MM-DD" => auto-parsed as midnight in UTC

        const end = new Date(lwStart);
        end.setMilliseconds(end.getMilliseconds() - 1);

        const yStart = new Date(currentDate);
        yStart.setDate(yStart.getDate() - 1);
        setToStartOfDay(yStart);

        const start = new Date(yStart);
        start.setMonth(start.getMonth() - 1);
        start.setDate(start.getDate() + 1);
        setToStartOfDay(start);

        return {
            StartDateUTC: toDateOnly(start),
            EndDateUTC: toDateOnly(end),
        };
    };

    const getPastEntriesFilter = (currentDate: Date) => {
        const { StartDateUTC: lmStartStr } = getLastMonthFilter(currentDate);
        const lmStart = new Date(lmStartStr);

        const end = new Date(lmStart);
        end.setMilliseconds(end.getMilliseconds() - 1);
        const start = new Date(1, 1, 1)
        start.setMilliseconds(start.getMilliseconds() - 1);
        return {
            StartDateUTC: toDateOnly(start),
            EndDateUTC: toDateOnly(end),
        };
    };

    const getDateFilters = (dateFilter: string[], currentDate: Date) => {
        // If none are selected (dateFilter.length === 0), default to all
        const yesterdayFilter = dateFilter.includes('sinceYesterday') || dateFilter.length === 0
            ? getYesterdayFilter(currentDate)
            : { StartDateUTC: null, EndDateUTC: null };

        const lastWeekFilter = dateFilter.includes('sinceLastWeek') || dateFilter.length === 0
            ? getLastWeekFilter(currentDate)
            : { StartDateUTC: null, EndDateUTC: null };

        const lastMonthFilter = dateFilter.includes('sinceLastMonth') || dateFilter.length === 0
            ? getLastMonthFilter(currentDate)
            : { StartDateUTC: null, EndDateUTC: null };

        const pastLogsFilter = dateFilter.includes('pastEntries') || dateFilter.length === 0
            ? getPastEntriesFilter(currentDate)
            : { StartDateUTC: null, EndDateUTC: null };

        return { yesterdayFilter, lastWeekFilter, lastMonthFilter, pastLogsFilter };
    };

    const composeSearchParameters = useCallback((): SearchLogDto => {
        const currentDate = new Date();
        const dateFilters = getDateFilters(dateFilter, currentDate);
        return {
            Groups: groups,
            YesterdayFilter: dateFilters.yesterdayFilter,
            LastWeekFilter: dateFilters.lastWeekFilter,
            LastMonthFilter: dateFilters.lastMonthFilter,
            PastLogsFilter: dateFilters.pastLogsFilter,
            SortingOptions: [],
            SearchTerm: searchInput,
        };
    }, [dateFilter, getDateFilters, groups, searchInput]);

    return (
        <SlackSearchFiltersContext.Provider
            value={{
                dateFilter,
                setDateFilter,
                searchInput,
                setSearchInput,
                groups,
                setGroups,
                composeSearchParameters,
            }}
        >
            {children}
        </SlackSearchFiltersContext.Provider>
    );
};
