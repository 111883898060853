import styled from "styled-components";

type ContextMenuProps = {
  offset: number;
};

// Common constants for menu positioning
export const SUBMENU_MOBILE_WIDTH = 140;
export const SUBMENU_DESKTOP_WIDTH = 200;
export const STANDARD_PADDING = 0;
export const ITEM_HEIGHT = 14; // Height of a single menu item

// Base styles for submenus that can be shared
export const submenuBaseStyles = {
  mobile: {
    zIndex: 101,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    overscrollBehavior: 'contain',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  desktop: {
    position: 'absolute',
    right: 'auto',
    overflowY: 'auto',
    overflowX: 'hidden'
  }
};

export const ContextMenuItem = styled.div<ContextMenuProps>`
  border-radius: 4px;
  position: fixed;
  width: 200px;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);
  z-index: 90;

  @media (max-width: 560px) {
    width: 140px;
  }
  
  ul {
    margin: 0;
    padding: 5px;
  }
  
  ul li {
    padding: 8px 12px;
    border-radius: 4px;
    color: gray;
  }

  ul li:hover {
    cursor: pointer;
    color: black;
  }

  .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    touch-action: manipulation;
  }
  
  .menu-item.active {
    background-color: rgba(0, 0, 0, 0.05);
    color: black;
  }
  
  .menu-sub-list {
    visibility: hidden;
    opacity: 0;
    display: none;
    background-color: #fff;
    padding: 8px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgb(64 64 64 / 15%);
    position: absolute;
    z-index: 100;
    width: 200px;
    flex-direction: column;
    max-height: 80vh;
    overflow-y: auto !important;
    overflow-x: hidden;
    overscroll-behavior: contain;
    transform: translateY(-5px);
    transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
    -webkit-overflow-scrolling: touch;
  }
  
  .open-sub-menu {
    visibility: visible;
    opacity: 1;
    display: flex;
    transform: translateY(0);
    transition: opacity 0.2s, transform 0.2s, visibility 0s;
  }
  
  .menu-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    touch-action: manipulation;
  }

  .circle {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
  }
  .c-r {
    background-color: red;
  }
  .c-g {
    background-color: green;
  }
  .c-y {
    background-color: yellow;
  }

  @media (max-width: 560px) {

    .menu-sub-list {
      width: 140px;
      position: fixed;
    }

    .open-sub-menu {
      transform: none;
    }
  }
`;
