import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MachineGroup, Title, GroupFamily, NavLink } from "../NavbarStyles";
import { AOO_API_HOST } from "../../../env";
import Routes from "../../Data/Routes";

function Groups() {
  let navigate = useNavigate();
  let [result, setResult] = useState([]);

  let url = AOO_API_HOST + "/aoo-api/Productions/machine-groups";
  function fetchMachines() {
    axios.get(url).then((response) => setResult(response.data));
  }

  useEffect(() => {
    fetchMachines();
  },[]);
  let handleClick = (event, group) => {
    navigate(Routes.MainRemoteMachine,{ state: group })
};

  return (
    <GroupFamily>
      <NavLink
        onClick={() => {
          navigate(Routes.MainRemote);
        }}
        style={{ marginRight: 30 }}
      >
        Back to menu
      </NavLink>

      <Title>Choose machine group</Title>
      <MachineGroup onClick={(e) => handleClick(e, " ")}>all</MachineGroup>
      {result.map((group, key) => (
        <MachineGroup key={key} onClick={(e) => handleClick(e, group.Id)}>
          {group.GroupName?.length !== 1
            ? group.GroupName
            : "(none)"}
        </MachineGroup>
      ))}
    </GroupFamily>
  );
}

export default Groups;
