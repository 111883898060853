export const READ_MACHINE_SLACK_STORE_KEY = "READ_MACHINE_SLACK_STORE_KEY";

export type ReadMachine = {
  machineId: string;
  postCount: number;
  expirationDate: string;
};

export class LocalStoreManager {
  /**
   * Checks if an item exists in localStorage by a given key.
   * @param key - The key to check.
   * @returns true if the item exists, otherwise false.
   */
  public hasItem(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }

  /**
   * Stores data in localStorage under a given key.
   * @param key - The key to store the data.
   * @param item - The data to be stored.
   */
  public setItem<T>(key: string, item: T): void {
    localStorage.setItem(key, JSON.stringify(item));
  }

  /**
   * Retrieves data from localStorage by a given key.
   * @param key - The key to retrieve the data.
   * @returns The parsed data or null if the key does not exist.
   */
  public getItem<T>(key: string): T | null {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  /**
   * Removes an item from localStorage by a given key.
   * @param key - The key to remove.
   */
  public removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}

export class SlackLocalStoreManager extends LocalStoreManager {
  private static instance: SlackLocalStoreManager;

  private constructor() {
    super();
  }

  /**
   * Returns an expiration date by adding 24 hours to the current time.
   * @returns The expiration date as an ISO string.
   */
  private getExpirationDate(): string {
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 24);

    return expirationDate.toISOString();
  }

  /**
   * Updates the list of stored machines in localStorage.
   * @param machines - The array of ReadMachine objects.
   */
  private updateMachines(machines: ReadMachine[]): void {
    this.setItem(READ_MACHINE_SLACK_STORE_KEY, machines);
  }

  /**
   * Returns the singleton instance of SlackLocalStoreManager.
   * @returns An instance of SlackLocalStoreManager.
   */
  public static getInstance(): SlackLocalStoreManager {
    if (!this.instance) {
      this.instance = new SlackLocalStoreManager();
    }
    return this.instance;
  }

  /**
   * Retrieves the list of all stored machines.
   * @returns An array of ReadMachine objects.
   */
  public getMachines(): ReadMachine[] {
    return this.getItem<ReadMachine[]>(READ_MACHINE_SLACK_STORE_KEY) ?? [];
  }

  /**
   * Adds a new machine to storage or updates an existing one.
   * @param machineId - The ID of the machine.
   * @param postCount - The post count for this machine.
   */
  public addMachine(machineId: string, postCount: number): void {
    this.removeMachine(machineId); //to avoid duplicates

    const readMachines = this.getMachines();

    readMachines.push({
      machineId,
      postCount,
      expirationDate: this.getExpirationDate(),
    });

    this.updateMachines(readMachines);
  }

  /**
   * Increases the postCount for an existing machine.
   * If the machine does not exist, it adds a new entry with postCount = 1.
   * @param machineId - The ID of the machine.
   */
  public addMachinePost(machineId: string): void {
    const readMachines = this.getMachines();
    const existingMachine = readMachines.find(
      (item) => item.machineId === machineId
    );

    if (existingMachine) {
      existingMachine.postCount += 1;
    } else {
      readMachines.push({
        machineId,
        postCount: 1,
        expirationDate: this.getExpirationDate(),
      });
    }

    this.updateMachines(readMachines);
  }

  /**
   * Removes a machine from storage by its ID.
   * @param machineId - The ID of the machine.
   */
  public removeMachine(machineId: string): void {
    const readMachines = this.getMachines().filter(
      (item) => item.machineId !== machineId
    );

    this.updateMachines(readMachines);
  }

  /**
   * Removes all expired entries from storage.
   */
  public cleanExpired(): void {
    const now = new Date().toISOString();
    const readMachines = this.getMachines().filter(
      (item) => item.expirationDate > now
    );

    this.updateMachines(readMachines);
  }
}
