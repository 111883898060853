import WorkerSvg from "../InspectionFlags/icons/Worker";

import {
  MachineName,
  MachineCode,
  BottomInfo,
  PinMachineIconContainer,
} from "./Styles";
import { Machine } from "./Styles";
import Active from "../InspectionFlags/icons/Active";
import { useNavigate, useLocation } from "react-router-dom";
import PushPinIcon from "@mui/icons-material/PushPin";
import { GlobalContext } from "../../../context/GlobalContext";
import { LogContext } from "../../../context/LogContext";
import { AOO_API_HOST } from "../../../env";
import Routes from "../../Data/Routes";

import React, { useState, useContext } from "react";
import axios from "axios";

function MachineTile({ machine, className }) {
  const {
    setMachineId,
    setLog,
    menuClicked,
    setMenuClicked,
    setisNavActive,
    backtoMenu,
    setBacktoMenu,
  } = useContext(GlobalContext);

  const [isLongClick, setIsLongClick] = useState(false);
  const [touchTimeout, setTouchTimeout] = useState(null);
  const [touchTime, setTouchTime] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleTouchStart = (e) => {
    setTouchTime(Date.now())
    const timeoutId = setTimeout(() => {
      setIsLongClick(true);
      const timeoutId = setTimeout(() => {
        setIsLongClick(false);
      }, 3000);
    }, 1000); 

    setTouchTimeout(timeoutId);
  };

  const handleTouchEnd = (e) => {
    if(Date.now() - touchTime > 1000){
      e.preventDefault()
    }
    clearTimeout(touchTimeout);
    setIsHovered(false)
  };

  const navigate = useNavigate();
  const postPinnedMachine = async (e) => {
    let url =
      AOO_API_HOST +
      `/aoo-api/ProductionsLog/Pinnedlog/` +
      machine.Id +
      "?isPinned=" +
      !machine.isPinned;
    try {
      await axios.post(url);
      machine.isPinned = !machine.isPinned;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    navigate(Routes.MainLog);
  };
  const handlePinMachine = (e) => {
    e.stopPropagation(); // Stop the propagation of the click event
    postPinnedMachine(e);
  };

  const openLog = () => {
    setMachineId(machine.Id);
    setLog(true);
    setMenuClicked(false);
    setisNavActive(false);
    setBacktoMenu(true);
  };

  // We add zero-width space after all '_' chars to allow line break at those position
  function allowLineBreaks(text) {
    return text.replace(/[_]/g, function (match) {
      return match === "_" ? "_\u200B" : " ";
    });
  }

  return (
    <Machine
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onClick={openLog}
      key={machine.MachineId}
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Active />
      <MachineName>
        <MachineCode>{machine.MachineCode} </MachineCode>
        <p>{allowLineBreaks(machine.MachineName)}</p>
        <PinMachineIconContainer isPinned={machine.isPinned}>
          <PushPinIcon
            style={{
              fontSize: "2.5rem",
              opacity: isLongClick || machine.isPinned || isHovered ? 0.9 : 0,
            }}
            onClick={handlePinMachine}
          ></PushPinIcon>
        </PinMachineIconContainer>
      </MachineName>
      <BottomInfo></BottomInfo>
    </Machine>
  );
}

export default MachineTile;
