import React, {useCallback, useContext} from "react";
import { useNavigate } from "react-router-dom";
import AirplayIcon from '@mui/icons-material/Airplay';

import {GlobalContext} from "../context/GlobalContext";
import Routes from "./Data/Routes";
export interface NavbarSlackViewProps {
    currentView: number,
    views: number,
    goToPreviousView: () => {},
    goToNextView: () => {}
}

export const NavbarSlackView = ({currentView, views, goToPreviousView, goToNextView}) => {
    const {
        setMachineId,
        setLog,
        setBacktoMenu,
        setIsSlackViewActive,
        setMenuClicked,
        setisNavActive,
    } = useContext(GlobalContext);
    const navigate = useNavigate();

    const closeLog = useCallback(() => {
        setMachineId(undefined);
        setLog(false);
       setBacktoMenu(false);
    }, [setBacktoMenu, setLog, setMachineId]);
    return (
        <div style={{
            height:'6vh',
            backgroundColor:'black',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'fixed',
            width: '100%',
        }}>
            <div style={{
                display:'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start'
            }}>
                <div style={{
                    display:'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: '15px',
                }}><span style={{
                    color: '#FFF',
                    fontFamily: 'Open Sans',
                    fontSize: '19px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: '120%',
                    textTransform: 'capitalize',
                    verticalAlign: 'middle'
                }}>Airvector Maintenance</span></div>
            </div>
            <div>
                <AirplayIcon style={{
                    color: 'white',
                    marginTop: '5px',
                    marginRight: '15px',
                    cursor: 'pointer'
                }} onClick ={() => {
                    setIsSlackViewActive(false);
                    closeLog();
                    goToPreviousView();
                    navigate(Routes.Main);
                    setMenuClicked(false);
                    setisNavActive(false);
                }}/>
            </div>
        </div>
    );
};
