import React, {useCallback, useEffect, useMemo, useState} from "react";
import Widgets from "../Widgets/index";
import { BrowserView } from "react-device-detect";
import { MachinesProvider } from "../../context/MachinesContext";
import { GlobalContextProvider } from "../../context/GlobalContext";
import Nav from "../Navbar/Navbar";
import KpiContent from "../Kpi/KpiContent";
import {SlackView} from "../Slack/SlackView";
const STATIC_VIEWS_COUNT = 2;
function Governance() {
  const [selectedView, setSelectedView] = useState(0);
  // Define handlers with useCallback so they don't change on every render
  const goToPreviousView = useCallback(() => {
    setSelectedView((prev) => Math.max(prev - 1, 0));
  }, []);

  const goToNextView = useCallback(() => {
    // Example max range: if we have 3 views, indices are 0 to 2
    const maxIndex = STATIC_VIEWS_COUNT - 1;
    setSelectedView((prev) => Math.min(prev + 1, maxIndex));
  }, []);
  const MachineDash = React.memo(function MachineDashComponent() {
    return (
        <>
          <BrowserView>
            <KpiContent />
          </BrowserView>
          <div className="mainContent">
            <Widgets />
          </div>
          <div className="bottomContent">
            <img
                className="logoContainer bottom"
                src={require("../../assets/images/logoNew.png")}
                alt="logo"
            />
          </div>
        </>
  )})
  // Use useMemo to store views in a stable reference.
  const views = useMemo(() => [
    <MachineDash goToPreviousView={goToPreviousView} goToNextView={goToNextView} key="machineView" />,
    <SlackView goToPreviousView={goToPreviousView} goToNextView={goToNextView} key="slackView" />

    // Add more components if needed
  ], [goToNextView, goToPreviousView]);
  useEffect(() => {
    let addr = new URL(window.location.href);
    addr.searchParams.entries();
    let cycle = -1;
    let dep = "STD";
    for (let key of addr.searchParams.keys()) {
      if (key === "cycle") {
        cycle = addr.searchParams.get(key);
        if (cycle !== undefined && cycle != null) {
          cycle = cycle * 1.0;
        }
      }
      if (key === "dep") {
        dep = addr.searchParams.get(key);
        if (dep == undefined && dep == null) {
          dep = "STD";
        }
        break;
      }
    }

    if (typeof cycle === "number" && cycle > 0) {
      setTimeout(() => {
        window.location.replace(
          //Local Test
          //`http://127.0.0.1:5500/?cycle=${cycle}`
          //Live
          `http://${dep}.dashboard.alfamega.com/?cycle=${cycle}`
        );
      }, cycle * 1000);
    }
  }, null);

  return (
    <MachinesProvider>
      <GlobalContextProvider>
        <div className="parentDiv">
          <Nav views={views} currentView={selectedView} goToPreviousView={goToPreviousView} goToNextView={goToNextView} />
          {views[selectedView]}
        </div>
      </GlobalContextProvider>
    </MachinesProvider>
  );
}

export default Governance;
