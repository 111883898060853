import React from 'react';

interface SlackViewViewSwitchProps {
    onClick?: () => void
}

export const SlackViewViewSwitch: React.FC<SlackViewViewSwitchProps> = ({onClick}: SlackViewViewSwitchProps) => {

    return (
        <></>
    )
}
