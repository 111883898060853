import React from 'react';

interface SlackViewDepartmentSelectorProps {

}

export const SlackViewDepartmentSelector : React.FC<SlackViewDepartmentSelectorProps> = () => {

    return (
        <></>
    )
}
