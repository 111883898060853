import React, { useContext } from "react";

import { GlobalContext } from "../../context/GlobalContext";

const Menu = ({ handleClick }) => {
  const { menuClicked, setMenuClicked } = useContext(GlobalContext);

  const onMenuClick = () => {
    setMenuClicked(!menuClicked);
    handleClick();
  };

  return (
    <img
      className="pointer"
      src={require("../../assets/icons/documents.png")}
      alt="documents"
      width="50px"
      height="50px"
      onClick={onMenuClick}
    />
  );
};

export default Menu;
