import Routes from "./Routes";
import {MenuDataType} from "./NavbarTypes";

export const MenuData: MenuDataType = [
  {
    title: "Main",
    link: Routes.Main,
    id: "",
    sections: [
      {
        title: "Inspection Flags",
        link: Routes.MainInspection,
        id: "",
        section: [
          {
            title: "Set new Inspection flag",
            link: Routes.MainSetFlagGroup,
            id: "",
            sections: [
              {
                title: "Select group",
                link: Routes.MainSetFlagGroup,
                id: "",
              },
              {
                title: "Select machine",
                link: Routes.MainSetFlagMachine,
                id: "",
              },
              {
                title: "Select worker",
                link: Routes.MainSetFlagWorker,
                id: "",
              },
              {
                title: "View result",
                link: Routes.MainSetFlagResult,
                id: "",
              },
            ],
          },
        ],
      },
      {
        title: "Resolved Flag History",
        link: Routes.MainResolved,
        id: "",
      },
      {
        title: "Machine Break Down Report",
        link: Routes.MainBreakdownReport,
        id: "",
        section: [
          {
            title: "Log options ",
            link: Routes.MainLogOptions,
            id: "",
          },
        ],
      },
      {
        title: "Machine Daily Loss Report",
        link: Routes.MainDialyLossReport,
        id: "",
        section: [
          {
            title: "Log options ",
            link: Routes.MainLogOptions,
            id: "",
          },
        ],
      },
    ],
  },
];
