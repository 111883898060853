import React, { useState } from "react";

import { QRScanner } from "./QRScanner";
import { ScanButton } from "./ScanButton";

interface RemoteScanQRCodeWrapperProps {
  onScanSuccess: (result: string) => void;
  isEnabled?: boolean;
  machineIdPrefix?: string;
}

export const RemoteScanQRCodeWrapper: React.FC<
  RemoteScanQRCodeWrapperProps
> = ({ onScanSuccess, isEnabled = true }) => {
  const isIosDevice =
    /iPad|iPhone|iPod/.test(navigator.userAgent) ||
    (navigator.userAgent.includes("Mac") && navigator.maxTouchPoints > 1);

  const [renderIosButton, setRenderIosButton] = useState(isIosDevice);

  const onQrCodeScannerIconClick = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({
        video: true,
      });

      setRenderIosButton(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {renderIosButton ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ScanButton
            onClick={onQrCodeScannerIconClick}
            width="100px"
            height="100px"
          />
        </div>
      ) : (
        <QRScanner onScanSuccess={onScanSuccess} isEnabled={isEnabled} />
      )}
    </>
  );
};
