import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { SlackViewUIContext } from "../Context/SlackViewUIContext";
import { SlackSearchResultsContext } from "../Context/SlackSearchResultsContext";
import { SlackSearchFiltersContext } from "../Context/SlackSearchFiltersContext";
import { useSearchInput } from '../Hooks/useSearchInput';

interface SlackViewSearchInputProps {
    placeholder?: string;
    initialValue?: string;
    onChange?: (value: string) => void;
}

const SearchBarContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #A665A8;
    border-radius: 16px;
    color: #fff;
    box-sizing: border-box;
`;

const InputRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
`;

const IconBase = styled.div`
  width: 24px;
  height: 24px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledSearchIcon = styled(SearchIcon)`
    && {
        width: 24px;
        height: 24px;
        margin: 0 5px;
        color: currentColor;
    }
`;

const StyledClearIcon = styled(ClearIcon)`
    && {
        width: 24px;
        height: 24px;
        margin: 5px;
        color: currentColor;
        cursor: pointer;
    }
`;

const StyledKeyboardArrowUpIcon = styled(KeyboardArrowUpIcon)`
    && {
        width: 24px;
        height: 24px;
        margin: 5px;
        color: currentColor;
        cursor: pointer;
    }
`;

const Input = styled.input`
    height: 52px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
    font-variant-numeric: lining-nums tabular-nums;
    text-overflow: ellipsis;
    font-family: 'Open Sans', Arial, sans-serif;
    font-size: 16px;
    width: 100%;
    padding: 0 8px;

    &::placeholder {
        color: #fff;
        opacity: 0.8;
    }

    &:focus {
        outline: none;
    }

    &::-webkit-search-cancel-button {
        display: none;
    }

    &::-ms-clear {
        display: none;
    }
`;

export const SlackViewSearchInput: React.FC<SlackViewSearchInputProps> = ({
                                                                              placeholder = 'Search...',
                                                                              onChange,
                                                                              initialValue,
                                                                          }) => {
    const { setIsSearchOpen } = useContext(SlackViewUIContext);
    const filtersCtx = useContext(SlackSearchFiltersContext);
    const searchCtx = useContext(SlackSearchResultsContext);

    const handleSearch = useCallback(async (value: string) => {
        if (!filtersCtx || !searchCtx) return;

        filtersCtx.setSearchInput(value);

        if (onChange) {
            onChange(value);
        } else {
            const searchParams = filtersCtx.composeSearchParameters();
            await searchCtx.fetchMachineLogs({
                ...searchParams,
                SearchTerm: value,
            });
        }
    }, [onChange, searchCtx, filtersCtx]);

    const { inputValue, handleInputChange, clearInput } = useSearchInput({
        initialValue,
        onSearch: handleSearch,
        debounceMs: 500,
        machineName: searchCtx.searchedMachinesFiltered?.SearchTermMachineName
    });

    const hideSearch = useCallback(() => {
        setIsSearchOpen(false);
    }, [setIsSearchOpen]);

    return (
        <SearchBarContainer>
            <InputRow>
                <StyledSearchIcon />
                <Input
                    autoFocus
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={(e) => handleInputChange(e.target.value)}
                    spellCheck={false}
                    autoComplete="off"
                    autoCorrect="off"
                />
            </InputRow>
            {inputValue ? (
                <IconBase onClick={clearInput}>
                    <StyledClearIcon />
                </IconBase>
            ) : (
                <IconBase onClick={hideSearch}>
                    <StyledKeyboardArrowUpIcon />
                </IconBase>
            )}
        </SearchBarContainer>
    );
};
