/**
 * Regular expression for validating GUIDs/UUIDs
 * Matches formats:
 * - 00000000-0000-0000-0000-000000000000
 * - {00000000-0000-0000-0000-000000000000}
 * - 00000000000000000000000000000000
 */
const GUID_REGEX = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$|^[0-9a-fA-F]{32}$/;

/**
 * Checks if a string is a valid GUID/UUID
 * @param value The string to check
 * @returns True if the string is a valid GUID/UUID, false otherwise
 */
export const isGuid = (value: string | null | undefined): boolean => {
    if (!value) return false;
    return GUID_REGEX.test(value);
};

/**
 * Formats a string as a GUID with hyphens
 * @param value The string to format
 * @returns The formatted GUID string or null if invalid
 */
export const formatGuid = (value: string): string | null => {
    if (!value) return null;

    // Remove any existing formatting
    const clean = value.replace(/[{}-]/g, '');

    // Check if it's a valid GUID
    if (clean.length !== 32 || !/^[0-9a-fA-F]{32}$/.test(clean)) {
        return null;
    }

    // Format with hyphens
    return [
        clean.slice(0, 8),
        clean.slice(8, 12),
        clean.slice(12, 16),
        clean.slice(16, 20),
        clean.slice(20)
    ].join('-');
};
