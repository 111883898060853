import React, {useCallback, useEffect, useState} from "react";
import { UrgencyOption, postWaitAction } from "../../Widgets/WidgetActions";

import { useNavigate, useLocation } from "react-router-dom";
import { StatusLink } from "../NavbarStyles";
import { AOO_API_HOST } from "../../../env";
import Routes from "../../Data/Routes";

type FinalOptionPropsType = {
  onFinalClick: (option: any) => void;
  machine: any;
  includeBackButton: boolean;
}

const Final:React.FC<FinalOptionPropsType> = ({onFinalClick = undefined, machine = undefined, includeBackButton = true}) => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [status, setStatus] = useState();
  const [event, setEvent] = useState({});
  const currentMachine = machine === undefined ? state.machine : machine;
  const options = UrgencyOption({
    color: currentMachine,
    isUrgent: currentMachine,
  })
  
  const onFinalClickCallback = useCallback(async (option) => {
    if (onFinalClick !== undefined)
      return onFinalClick(option);
    postWaitAction(
        AOO_API_HOST + `/aoo-api/Productions/post-status-urgency`,
        {
          MachineId: state.machine.MachineId,
          Status: option.color,
          IsUrgent: option.isUrgent,
        }
    ).then((response) => {
      // const responseData = response.data;
      setEvent(response.data);
      if (!option.isUrgent && option.isUrgent != null) {
        navigate(Routes.MainRemoteReason, {
          state: { event: response.data },
        });
      } else {
        navigate(Routes.Main);
      }
    });
  }, [event, navigate, onFinalClick, state?.machine?.MachineId]);

  useEffect(() => {
    if (status !== state?.machine?.EventState) {
      setStatus(state?.machine?.EventState);
    }
  }, [state?.machine?.EventState, status]);
  return (
    <>
      {includeBackButton && (
          <StatusLink
              onClick={() => {
                navigate(Routes.MainRemoteMachine, { state: state.group });
              }}
          >
            Back to groups
          </StatusLink>
      )}
      {options.map((option, key) => (
        <StatusLink
          key={key}
          onClick={() => {
            onFinalClickCallback(option);
          }}
        >
          {option.Title}
        </StatusLink>
      ))}
    </>
  );
}

export default Final;
