import { SUBMENU_MOBILE_WIDTH, SUBMENU_DESKTOP_WIDTH, STANDARD_PADDING, ITEM_HEIGHT } from './ContextMenu';
import { isMobile } from 'react-device-detect';
// Position types
export type SubmenuPosition = 'right' | 'left' | 'below' | 'above';
export type SubmenuSize = 'mobile' | 'desktop';

// Interfaces for positioning functions
interface RectInfo {
  left: number;
  right: number;
  top: number;
  bottom: number;
  width: number;
  height: number;
}

export interface SpaceInfo {
  spaceRight: number;
  spaceLeft: number;
  spaceBelow: number;
  spaceAbove: number;
  windowWidth: number;
  windowHeight: number;
}

// Generate common style object for submenu based on position type
export const getSubmenuPosition = (
  position: SubmenuPosition,
  rootRect: RectInfo,
  spaceInfo: SpaceInfo,
  size: SubmenuSize = 'desktop'
): React.CSSProperties => {
  const submenuWidth = isMobile ? SUBMENU_MOBILE_WIDTH : SUBMENU_DESKTOP_WIDTH;
  const { windowWidth, windowHeight, spaceBelow, spaceAbove } = spaceInfo;
  
  // Common styles for all positions
  const baseStyle: React.CSSProperties = {
    width: `${submenuWidth}px`,
    overflowY: 'auto',
    position: isMobile ? 'fixed' : 'absolute',
    right: 'auto'
  };
  
  // Calculate maximum available height
  const getMaxHeight = (pos: SubmenuPosition) => {
    const isVertical = pos === 'below' || pos === 'right';
    const space = isVertical ? spaceBelow : spaceAbove;
    
    return isMobile
      ? Math.min(windowHeight - 2 * STANDARD_PADDING, windowHeight * 0.7)
      : Math.min(windowHeight * 0.8, space - 2 * STANDARD_PADDING);
  };
  
  // Common mobile position values
  const getMobilePositionStyles = (pos: SubmenuPosition) => {
    // Safe horizontal position for below/above cases
    const safeLeft = Math.max(
      STANDARD_PADDING, 
      Math.min(rootRect.left, windowWidth - submenuWidth - STANDARD_PADDING)
    );
    
    // Position-specific styles for mobile
    const mobileStyles: Record<SubmenuPosition, React.CSSProperties> = {
      right: {
        left: `${rootRect.right + 5}px`,
        top: `${Math.max(STANDARD_PADDING, rootRect.top)}px`,
        bottom: 'auto'
      },
      left: {
        right: `${windowWidth - rootRect.left + 5}px`,
        left: 'auto',
        top: `${Math.max(STANDARD_PADDING, rootRect.top)}px`,
        bottom: 'auto'
      },
      below: {
        left: `${safeLeft}px`,
        top: `${rootRect.bottom + 5}px`,
        bottom: 'auto'
      },
      above: {
        left: `${safeLeft}px`,
        bottom: `${windowHeight - rootRect.top + 5}px`,
        top: 'auto'
      }
    };
    
    return mobileStyles[pos];
  };
  
  // Common desktop position values
  const getDesktopPositionStyles = (pos: SubmenuPosition) => {
    // Position-specific styles for desktop
    const desktopStyles: Record<SubmenuPosition, React.CSSProperties> = {
      right: {
        left: '100%',
        top: '0',
        bottom: 'auto'
      },
      left: {
        left: '-100%',
        top: '0',
        bottom: 'auto'
      },
      below: {
        left: '0',
        top: '100%',
        bottom: 'auto'
      },
      above: {
        left: '0',
        bottom: '100%',
        top: 'auto'
      }
    };
    
    return desktopStyles[pos];
  };
  
  // Create position styles based on device type
  const positionStyles = isMobile 
    ? getMobilePositionStyles(position) 
    : getDesktopPositionStyles(position);
  
  // Calculate max height based on position and available space
  const maxHeight = getMaxHeight(position);
  
  // Final styles with all properties combined
  return {
    ...baseStyle,
    ...positionStyles,
    maxHeight: `${maxHeight}px`
  };
};

// Helper function to determine optimal position for submenu
export const getOptimalSubmenuPosition = (
  rootRect: RectInfo,
  spaceInfo: SpaceInfo,
  submenuRef: HTMLElement | null,
  size: SubmenuSize = 'desktop'
): SubmenuPosition => {
  const submenuWidth = isMobile ? SUBMENU_MOBILE_WIDTH : SUBMENU_DESKTOP_WIDTH;
  const { spaceRight, spaceLeft, spaceBelow, spaceAbove } = spaceInfo;
  
  // For mobile, check side spaces first, then above/below
  if (isMobile) {
    if (spaceRight >= submenuWidth + STANDARD_PADDING) {
      return 'right';
    } else if (spaceLeft >= submenuWidth + STANDARD_PADDING) {
      return 'left';
    } else if (spaceBelow >= 200 || spaceBelow > spaceAbove) {
      return 'below';
    } else {
      return 'above';
    }
  } 
  
  // For desktop
  // Determine if content would overflow
  let estimatedHeight = 0;
  if (submenuRef) {
    const itemCount = submenuRef.querySelectorAll('li').length;
    estimatedHeight = itemCount * ITEM_HEIGHT + 16;
  }
  
  // Default to right side
  let position: SubmenuPosition = 'right';
  
  // Not enough space on right, place on left
  if (rootRect.right + submenuWidth > spaceInfo.windowWidth) {
    position = 'left';
  }
  
  // Check if submenu would overflow at bottom and adjust if needed
  if (submenuRef && estimatedHeight > spaceBelow && spaceAbove > spaceBelow) {
    // Fix: We want to position above the menu, not necessarily keep the same horizontal position
    position = position === 'right' ? 'above' : 'above';
  }
  
  return position;
};

// Get the submenu reference based on type - helper function for external use
export const getSubmenuRefByType = (submenuType: string, refs: Record<string, HTMLElement | null>): HTMLElement | null => {
  switch (submenuType) {
    case 'worker': return refs.workerRef || null;
    case 'reason': return refs.reasonRef || null;
    case 'fix': return refs.fixRef || null;
    case 'status': return refs.statusRef || null;
    case 'inspector': 
    case 'assign-inspector': 
      return refs.inspectorRef || null;
    default: return null;
  }
}; 