import React, { useEffect, useState, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { NavLink } from "../NavbarStyles";
import { DownloadBtn, Title, ReportInfoForm, ReportLabel, DatePickerDiv, DatePickerBlock, CheckboxInput, ButtonsDiv, DatePickerLabel, ToggleSwitchDiv, ToggleLabel, SwitchInput } from "./Styles";
import { AOO_API_HOST } from "../../../env";
import Routes from "../../Data/Routes";

const MachinesDailyLossReport = () => {
  // State for date pickers and checkboxes
  const [startDate, setStartDate] = useState(new Date()); // Default to today's date
  const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
  const [reportFormat, setReportFormat] = useState('CSV');  // Set CSV as the default format
  const [reportProportionalCosts, setReportProportionalCosts] = useState('Proportional Costs')
  const [minDate, setMinDate] = useState(new Date());
  const navigate = useNavigate();

  // Prepare query parameters (common for both PDF and CSV)
  const prepareQueryParams = () => {
    return {
      StartDate: startDate.toISOString(),
      EndDate: getEndDate(endDate).toISOString(),
      IsCsv: reportFormat == 'CSV',
      IsProportionalCosts: reportProportionalCosts == 'Proportional Costs',
    };
  };

  const getEndDate = (date) => {
    date.setHours(23, 59, 59, 999);
    return date;
  }

  const getMinDate = useCallback(async () => {
    //e.preventDefault();
    const endpoint = '/aoo-api/Productions/GetMinReportDateCA';
    try {
      const response = await axios.get(AOO_API_HOST + endpoint);
      let minDate = new Date(response.data);
      if(minDate !== undefined)
      {
        setMinDate(minDate);
        setStartDate(minDate);
      }
    } catch (error) {
      console.error(`Error fetching ${reportFormat} report:`, error);
    }
  }, [setMinDate]);

  useEffect(() => {
    if(getMinDate !== undefined)
    {
      getMinDate();
    }
  }, [getMinDate]);

  // Handle report download (PDF or CSV based on toggle)
  const handleDownloadReport = async (e) => {
    
    e.preventDefault();

    if (!startDate || !endDate) {
      alert('Please select both start and end dates');
      return;
    }

    const params = prepareQueryParams();
    const endpoint = '/aoo-api/Productions/GetMachineDailyLossReport';

    const fileType = reportFormat === 'PDF' ? 'application/pdf' : 'text/csv';
    const fileExtension = reportFormat === 'PDF' ? 'pdf' : 'csv';

    try {
      const response = await axios.post(AOO_API_HOST + endpoint, params,
      {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'blob',
      }
    );

      const url = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `machine-daily-loss-report-${new Date().toISOString()}.${fileExtension}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(`Error fetching ${reportFormat} report:`, error);
    }
  };

  return (
    
    <div>
      <NavLink
        onClick={() => navigate(Routes.Main)}
        style={{ marginRight: 30 }}>
        Back to menu
      </NavLink>
      <Title>Machine Daily Loss Report</Title>
      <ReportInfoForm>
        <form>
          {/* Date Pickers */}
          <DatePickerBlock>
            <DatePickerDiv>
                <DatePickerLabel>Start Date: </DatePickerLabel>
                <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                dateFormat="yyyy-MM-dd"
                className="report-datepicker"
                minDate={minDate}
                />
            </DatePickerDiv>

            <DatePickerDiv>
                <DatePickerLabel>End Date: </DatePickerLabel>
                <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                dateFormat="yyyy-MM-dd"
                className="report-datepicker"
                minDate={minDate}
                />
            </DatePickerDiv>
          </DatePickerBlock>

          {/* Toggle Switch for PDF/CSV */}
          <ToggleSwitchDiv>
            <ToggleLabel>Download as: {reportFormat}</ToggleLabel>
            <SwitchInput
              type="checkbox"
              checked={reportFormat === 'CSV'}
              onChange={(e) => setReportFormat(e.target.checked ? 'CSV' : 'PDF')}
            />
          </ToggleSwitchDiv>

          {/* Single Button for downloading */}
          <ButtonsDiv>
            <DownloadBtn onClick={handleDownloadReport}>Download {reportFormat}</DownloadBtn>
          </ButtonsDiv>
        </form>
      </ReportInfoForm>
    </div>
  );
};

export default MachinesDailyLossReport;
