import React from "react";
import { useNavigate } from "react-router-dom";
import { GroupFamily, NavLink } from "../NavbarStyles";
import {Section} from "../../Data/NavbarTypes";
import {MenuData} from "../../Data/MenuData";
import Routes from "../../Data/Routes";


export const RemoteScanSubmenu = () => {
    const sections = MenuData[0].sections[2].sections;
    let navigate = useNavigate();
    return (
       <div>
           <GroupFamily>
               <NavLink
                   onClick={() => {
                       navigate(Routes.Main);
                   }}
                   /*style={{ marginRight: 30 }}*/
               >
                   Back to menu
               </NavLink>
               {sections.map((item: Section, index: number) => {
                   return (
                       <div key={item.title + index} style={{width: '100%'}}>
                           <li key={index}>
                               <NavLink
                                   key={index}
                                   onClick={() => {
                                       navigate(item.link);
                                   }}
                               >
                                   {item.title}
                               </NavLink>
                           </li>
                       </div>
                   )
               })}
           </GroupFamily>
       </div>
    )

}